import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import { Tabs , Spin, Divider, List, Form, Input,Switch, Button,Select, Table } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import SearchTable from "../../components/SearchTable";
import API2 from "../../components/api2";

export default function CheckInsInner(props) {

	let id = props.id;

    const AP = API2();

	const [checkins, setCheckins] = useState([]);
	const [checkinsSummary, setCheckinsSummary] = useState([]);
	const [checkinsSummaryColumns, setCheckinsSummaryColumns] = useState({});

	const checkInColumns = [
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
			sorter: true,
			align:'left',
			render: (text, record) => (
				<div>
					<Moment format="DD/MM/YYYY" unix>{record.date}</Moment>
				</div>
			)
		},
		{
			title: 'Average Weekly Weight',
			key: 'weight',
			dataIndex: 'weight',
			sorter: true,
			align:'left',
		},
		{
			title: 'Actions',
			key: 'actions',
			width: '200px',
			render: (text, record) => (
				<div>
					<span><NavLink to={(id)?"/client/"+id+"/checkin/"+record.id:"/checkin/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
		},
	];



    useEffect(() => {
        AP.get((id)?"/client/"+id+"/checkins":"/checkins")
            .then(function(res){
                setCheckins(res.data);
            });

    }, []);

	useEffect(() => {

		let columns = [
			{
				title: 'Question',
				key: 'question',
				dataIndex: 'question',
				sorter: (a, b) => {return (!a.question)?-1:a.question.localeCompare(b.question)},
				align: 'left',
                width: '200px'
			}
		];

		let dark = true;
		for(let c of checkins){
			columns.push({
				title: <div>
                    <Moment format="DD/MM/YYYY" unix>{c.date}</Moment>
                    <NavLink to={(id)?"/client/"+id+"/checkin/"+c.id:"/checkin/"+c.id} style={{position:"absolute", right:0}}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink>
                </div>,
				key: c.id,
				dataIndex: c.id,
				align: 'center',
                width: '350px',
                className: (dark?'darkColumn':'')
			});
			dark = !dark;
		}

		const nameMap = {
            "weight": "Weight",
            "rpe": "RPE for the week",
            "sessions": "Number of sessions completed out of planned",
            "percentage": "Compliance percentage for the week",
            "considerations": "Compliance considerations",
            "macros_protein": "Average Weekly Protein",
            "macros_carb": "Average Weekly Carbs",
            "macros_fat": "Average Weekly Fat",
            "macros_fibre": "Average Weekly Fibre",
            "sleep_hours": "Average hours sleep",
            "sleep_quality": "Average sleep quality",
            "steps": "Average Daily Steps",
            "water": "Average Daily litres of water drunk",
            "energy": "Average Weekly Kcals",
            "hunger": "Hunger",
            "stress": "Stress"
        };

		let rowData = [];
		for(let f of [
		    "title.Weight","weight","comments.weight",
            "title.Training","rpe","sessions","comments.training","comments.client_training",
            "title.Nutrition","percentage","considerations","macros_protein","macros_carb","macros_fat","macros_fibre","comments.nutrition","comments.client_nutrition",
            "title.Lifestyle","sleep_hours","sleep_quality","steps","water","comments.sleep","comments.client_sleep",
            "title.Other","energy","hunger","stress","comments.other","comments.client_other",
        ]){

		    const isComment = f.substr(0,9) == "comments.";
            const isClientComment = f.substr(0,16) == "comments.client_";
            const isTitle   = f.substr(0,6) == "title.";

            let question = '';
            if(isClientComment){
                question = 'Client Comment';
            }else if(isComment){
                question = 'Coach Comment';
            }else if(isTitle){
                question = f.substr(6);
            }else{
                question = nameMap[f];
            }

			let row =  {
				"question":  question,
                "isComment": isComment,
                "isTitle":   isTitle
			};

			for(let c of checkins){
			    if(isTitle){
                    row[c.id] = '';
                }
			    else if(isComment){
			        if(c.comments) {
                        row[c.id] = c.comments[f.substr(9)];
                    }else{
                        row[c.id] = '';
                    }
                }
				else if(f=="weight"){
					row[c.id] = c[f];
				}
                else if(["sleep_quality","energy","hunger","stress"].indexOf(f) != -1){
			        if(c.compliance[f]) {
                        row[c.id] = c.compliance[f] + "/5";
                    }else{
                        row[c.id] = "";
                    }
                }
				else {
					row[c.id] = c.compliance[f];
				}
			}

			rowData.push(row);
		}

		setCheckinsSummary(rowData);
		setCheckinsSummaryColumns(columns);

	}, [checkins]);


    return (

        <>
            <div style={{display:"flex",paddingBottom:"10px"}}>
                <NavLink to={id?"/client/"+id+"/checkin/new":"/checkin/new"} activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Add Check In </Button>
                </NavLink>
            </div>

            {!!checkins.length &&
            <>
                <h3>Summary</h3>
                <Table
                    dataSource={checkinsSummary}
                    //loading={loading}
                    tableLayout="fixed"
                    pagination={false}
                    scroll={{ x: 1500 }}
                    columns={checkinsSummaryColumns}
                    rowClassName={function(record,index){
                        if(record.isTitle){
                            return 'titleRow';
                        }
                        return '';
                    }}
                />
            </>
            }
        </>
	);
}