import React, {useEffect, useState} from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import API2 from "../../components/api2";
import { useHistory } from "react-router-dom";
import useToken from "../../hooks/useToken";





export default function ForgotPassword(props) {
	const AP = API2();
	const [form] = Form.useForm();
    const history = useHistory();
    const [done, setDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);
    const { user, setUser } = useToken();

	const handleRequest = async ( values: any) => {
        setIsSaving(true);
		AP.post('/forgotPasswordRequest',values)
			.then(function(res){
                setDone(true);
                setIsSaving(false);
			})
			.catch(function (error) {
                setIsSaving(false);
			});
	};
    const handleSubmit = async ( values: any) => {
        setIsSaving(true);

        values.token = props.match.params.token;
        AP.post('/forgotPasswordSubmit',values)
            .then(function(res){
                setUser(res.user, res.access_token);
                window.location.replace("/dashboard");
            })
            .catch(function (error) {
                setIsSaving(false);
                setInvalidToken(true);
            });
    };

	return(
		<div className="content_inner">

			{props.match.params.token &&
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{remember: true}}
                onFinish={handleSubmit}
                style={{ width:"290px",margin:"auto"}}
            >
                <Form.Item>
                    <h3 style={{color:"red"}}>{invalidToken && "Invalid Token"}</h3>
                </Form.Item>

				<Form.Item
					name="password"
					label="Password"
					rules={[
						{
							required: true,
							message: 'Please input new password!',
						},
					]}
					hasFeedback
				>
					<Input.Password />
				</Form.Item>
                <Row style={{paddingTop:20}}>
                    <Col style={{marginLeft:"auto"}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={isSaving}>Update Password</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>


				|| (done &&
                	<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><i className="far fa-check-square" style={{color:"green",fontSize:70}}/><h1 style={{paddingLeft:50}}>Success</h1></div>
				)
				|| (
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={handleRequest}
                    style={{ width:"290px",margin:"auto"}}
                >
                    <Form.Item>
                        <p>This will send an email with details as to how to reset your password</p>
                    </Form.Item>

                    <Form.Item
                        name="username"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            }
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="email address"/>
                    </Form.Item>
                    <Row style={{paddingTop:20}}>
                        <Col style={{marginLeft:"auto"}}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" loading={isSaving}>Request Password Change</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
				)

			}
		</div>
	)
}

