import React, {useEffect, useState} from 'react';

import {
    Form,
    Input,
    InputNumber,
    Select,
	Radio,
    Button,
	Slider,
	Divider,
    DatePicker,
    Steps,
    Spin
} from 'antd';
import axios from 'axios';
import Upload from "../components/Uploader";
import { QuestionCircleOutlined, InboxOutlined  } from '@ant-design/icons';
import API from "../components/api";
import useToken from "../hooks/useToken";
import {notification} from "antd/lib/index";
import API2 from "../components/api2";
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser';


const { Option } = Select;
const { Step } = Steps;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

let overallRisk = '';

export default function RiskAssessment(props) {
    const [form] = Form.useForm();
    const { user, setUser } = useToken();
    const [clearanceDoc, setClearanceDoc] = useState([]);
	const AP = API2();

	let defaultRisk = 'low';
	if(props.overview){
        defaultRisk = props.overview.risk;
    }
    const [risk, setRisk] = useState(defaultRisk);
    const [questions, setQuestions] = useState([]);
    const [formattedQuestions, setFormattedQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [failedValidation, setFailedValidation] = useState(0);

    useEffect(() => {

        let url;
        if(props.client){
            url = '/client/'+props.client.id+'/riskAssessment';
        }
        else if(props.coach){
            url = '/coach/'+props.coach.id+'/riskAssessment';
        }
        else if(props.match && (props.match.params.clientId && props.match.params.coachId)){
            if(window.location.pathname.substring(0,14) == "/iframe/coach/"){
                url = '/coach/'+props.match.params.coachId+'/client/'+props.match.params.clientId+'/riskAssessment';
            }else{
                url = '/client/'+props.match.params.clientId+'/coach/'+props.match.params.coachId+'/riskAssessment';
            }
        }

        if(url) {
            AP.get(url)
                .then(function (questions) {
                    setLoading(false);
                    setQuestions(questions);
                    let fq = {};
                    let formData = {};
                    let formNames = [];
                    for(let q of questions){
                        if(!fq[q.category]) fq[q.category] = [];
                        fq[q.category].push(q);

                        formNames.push(q.name);

                        if(q.value){
                            if(q.type=="date"){
                                q.value = moment(q.value, 'YYYY/MM/DD');
                            }
                            formData[q.name] = q.value;
                        }

                        if(q.name == "g_medical_conditions" && q.value){
                            setIsEdit(true);
                        }



                        if(q.type=='file' && q.file){
                            setClearanceDoc([{
                                uid: q.file.id,
                                name: q.file.name,
                                status: 'done',
                                url: q.file.endpoint,
                                thumbUrl: q.file.endpoint
                            }]);
                        }

                        if (q.category == "rating") {
                            formData[q.name] *= 20;
                        }
                    }
                    for(let q of questions){
                        if(q.warnings){
                            for(let w of q.warnings){
                                if(w.formula){
                                    w.linkedTo = [];
                                    for(let n of formNames){
                                        if(w.formula.indexOf('.'+n)!=-1){
                                            w.linkedTo.push(n);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    setFormattedQuestions(fq);

                    form.setFieldsValue(formData);
                });
        }
    }, [props]);

    const [currentStep, setCurrentStep] = useState(0);
    function stepChange(step){
        setCurrentStep(step);
    };

    function saveForm(data){
        setFailedValidation(0);

    	for(let q of questions){
            //get profile image value
            if(q.type && (q.type == 'file' || q.type == 'files')) {
                data[q.name] = null;
                for (let f of clearanceDoc) {
                    if (f.attachment_id) data[q.name] = f.attachment_id;
                    else data[q.name] = f.uid;
                }
            }

            if (q.category == "rating"){
                data[q.name] /= 20;
            }
		}

		let url;
        if(props.client) {
            url = '/client/' + props.client.id + '/riskAssessment';
        }else if(props.coach){
            url = '/coach/'+props.coach.id+'/riskAssessment';
        }
        else if(props.match.params.clientId && props.match.params.coachId){
            if(window.location.pathname.substring(0,14) == "/iframe/coach/"){
                url = '/coach/'+props.match.params.coachId+'/client/'+props.match.params.clientId+'/riskAssessment';
            }else{
                url = '/client/'+props.match.params.clientId+'/coach/'+props.match.params.coachId+'/riskAssessment';
            }
        }
        AP.patch(url,data)
            .then(function(res){
                notification.success({
                    message: `Saved`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });
            });
    }
    function saveFormFail(data){
        setFailedValidation(data.errorFields.length);
    }



    const makeItem = function(q){

        if(q.type=='select'){

            let options = q.options;
    		if(q.category=="rating"){
    			let newOptions = {};
                Object.keys(options).map(function(key) {
                    newOptions[key*20] = options[key];
                });
                return <Slider
                    marks={newOptions}
					min={20}
					step={20}
                    max={Object.keys(newOptions).length*20}
                />
			}
			else {
    		    if(options.yes && options.no){
                    return <Radio.Group>
                        {Object.keys(options).map(key =>
                            <Radio.Button value={key} key={key}>{options[key]}</Radio.Button>
                        )}
                    </Radio.Group>
                }else{
    		        return <Select>
                        {Object.keys(options).map(key =>
                        <Option value={key} key={key}>{options[key]}</Option>
                        )}
                    </Select>
                }


            }
		}
		else if(q.type=='file' || q.type=='file'){

			return <Upload files={clearanceDoc} maxCount={1} setFiles={setClearanceDoc}/>
		}
        else if(q.type=='date'){
            return <DatePicker format="DD/MM/YYYY"/>
        }
		else{

            if(q.validate == "number"){
                return <InputNumber addonAfter={q.suffix}  placeholder={q.placeholder}/>
            }else{
                return <Input addonAfter={q.suffix}  placeholder={q.placeholder}/>
            }


		}


	};
    const checkCondition = function(conditions){
        if(!conditions) return true;

        for(let field in conditions){
            let fieldValue = form.getFieldValue(field);
            if(fieldValue != conditions[field]) return false;
        }

        return true;
    }
    const questionRisk = {};
    function addMessage(messages,w,q){
        if(q){
            messages.push(<span style={{color: severityColorMap[w.severity]}}>{w.message}</span>)
        }else{
            messages.push(<div style={{color: severityColorMap[w.severity], paddingTop:"20px"}}>{w.message}</div>)
        }

        let n;
        if(q) { // && w.contributes
            n = q.name;
        }else {
            n = w.id;
        }

        if (w.severity == 'high') {
            questionRisk[n] = {severity:w.severity,contributes:(w.contributes?true:false)};
        }
        else if (w.severity == 'moderate' && (!questionRisk[n] || questionRisk[n].severity != 'high')) {
            questionRisk[n] = {severity:w.severity,contributes:(w.contributes?true:false)};
        }
    }
    function updateRisk(){
        //console.log(questionRisk);
        //overall risk
        let nRisk = '';
        for(let s of Object.values(questionRisk)){

            if(!s || !s.severity || !s.contributes){
                continue;
            }

            if(s.severity=='high'){
                nRisk = s.severity;
                break;
            }
            else if(s.severity=='moderate' && (nRisk=='low' || nRisk=='')){
                nRisk = s.severity;
            }
        }
        if(!nRisk) nRisk = 'low';

        if (overallRisk != nRisk) {
            overallRisk = nRisk;
            setRisk(nRisk);
        }
    }
    function valueChanged(changedValues, allValues){
        let count = 0;
        for(let x in allValues){
            if(allValues[x]) count++;
        }

        setPercentCompleted((count/Object.keys(allValues).length)*100);
    }

    const layouts = {
    	"generalIn":{
            labelCol: { span: 14 }
        },
        "generalOut":{
            labelCol: { span: 14 }
        },
        "Relationship with FoodIn":{
            labelCol: { span: 18 }
        },
        "Relationship with FoodOut":{
            labelCol: { span: 18 }
        },
		"ratingIn":{
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { offset: 2,span: "21-i" },
                sm: { offset: 2,span: 14 },
            },
        },
        "ratingOut":{
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 },
            },
        },
        "medicalIn":{
            labelCol: { span: 16}
        },
        "medicalOut":{
            labelCol: { span: 16}
        }
	};
	const riskColor = {
        'high':'red',
        'moderate':'orange',
        'low':'green'
    };

    const stepMap={
        0:"general",
        1:"Relationship with Food",
        2:"rating",
        3:"medical"
    };
    const severityColorMap={
        "":         "orange",
        "low":      "general",
        "moderate": "orange",
        "high":     "red"
    };


	function customRequest({action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials}){

    	function progress(p){
			onProgress({
				percent: p
			});
		}

		return AP.post('/attachment',{'name':file.name})
			.then(function(res){
				file.attachment_id = res.id;

				axios.request({
					method: 'put',
					url: res.upload_endpoint,
					data: file,
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: (p) => {
						onProgress({
							percent: p.total
						});
					}
				}).then (data => {
					onSuccess();
				});
			});
	}

	function shouldUpdate(prevValues, curValues, key, question){

	    if(prevValues[question.name] !== curValues[question.name]) return true;

	    if(question.condition){
	        for(let n of Object.keys(question.condition)){
                if(prevValues[n] !== curValues[n]) return true;
            }
        }

        if(question.warnings && question.warnings.length){
            for(let w of question.warnings){
                if(w.formula && w.linkedTo){
                    for(let g of w.linkedTo){
                        if(prevValues[g] !== curValues[g]) return true;
                    }
                }
                if(w.condition=='group'){
                    for(let g of w.group_ids){
                        if(prevValues[g] !== curValues[g]) return true;
                    }
                }
            }
        }

        return false;
    }

    return(
        <>
            <Form
                {...formItemLayout}
                form={form}
                name="riskAssessment"
                onFinish={saveForm}
                onFinishFailed={saveFormFail}
                initialValues={[]}
                scrollToFirstError
                style={{ maxWidth:(props.steps)?"800px":"initial",margin:"auto",paddingTop:(props.steps)?"20px":"0px"}}
                //layout="vertical"d
                onValuesChange={valueChanged}
                className={!props.steps?"form-compressed":""}
            >

                {props.steps &&
                <Steps current={currentStep} onChange={stepChange}  percent={percentCompleted}>
                    {Object.keys(formattedQuestions).map(function (key) {
                        return <Step title={key.charAt(0).toUpperCase() + key.substr(1)} description="" status={isEdit?"finish":null}/>
                    })}
                </Steps>
                    || risk && <h1 style={{color:riskColor[risk],textAlign:"center"}}>{risk.toUpperCase()} RISK</h1>
                }
                {(loading
                &&
                <div style={{display:"flex",  justifyContent:"center"}}>
                    <Spin/>
                </div>
                )
                ||
                <>
                    <div style={{paddingTop:(props.steps)?"60px":"0px", animation:"fade .3s ease-in"}}>
                        {Object.keys(formattedQuestions).map(function(key) {
                            return <div className={key+"Section"} key={key} style={{display:((props.steps && stepMap[currentStep]!=key)?'none':'block')}}>
                                {!props.steps &&
                                <Divider style={{paddingTop:20}}>{key.charAt(0).toUpperCase()+key.substr(1)}</Divider>
                                }
                                {formattedQuestions[key].map(question =>
                                    <div key={question.id} >
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) => shouldUpdate(prevValues, curValues, key, question)}
                                        >
                                            {({ getFieldValue  }) => {
                                                let resp = (checkCondition(question.condition)) ? (
                                                    <Form.Item
                                                        label={question.description}
                                                        style={{marginBottom: "0px"}}
                                                        {...layouts[question.category + "Out"]}
                                                        required={question.required}
                                                    >
                                                        <Form.Item
                                                            name={question.name}
                                                            //extra="Do you have any medical conditions that affect your ability to exercise or follow a nutrition program?"
                                                            rules={function (q) {
                                                                //if(q.id == 29)debugger;
                                                                let rules = [
                                                                    {
                                                                        required: q.required,
                                                                        type: (q.validate == "number")
                                                                            ?'number'
                                                                            :'any',
                                                                        message: (q.validate == "number")
                                                                            ?'Please input a number'
                                                                            :'Please pick an item!',
                                                                        transform(value) {
                                                                            if(value == null) return value;
                                                                            if(q.validate == "number"){
                                                                                return Number(value);
                                                                            }else{
                                                                                return value;
                                                                            }

                                                                        }
                                                                    },
                                                                ];

                                                                return rules;
                                                            }(question)}
                                                            help={function (q) {
                                                                if (q.warnings && q.warnings.length) {

                                                                    let messages = [];
                                                                    let value = form.getFieldValue(q.name);

                                                                    if (q.category == "rating") {
                                                                        value = value / 20;
                                                                    }

                                                                    questionRisk[q.name] = false;
                                                                    let groups = {};

                                                                    for (let w of q.warnings) {
                                                                        if(w.formula){
                                                                            let d = form.getFieldsValue(true);
                                                                            if (eval(w.formula)) addMessage(messages, w, q);
                                                                        } else if (w.condition == '<') {
                                                                            if (parseFloat(value) < parseFloat(w.value)) addMessage(messages, w, q);
                                                                        } else if (w.condition == '<=') {
                                                                            if (parseFloat(value) <= parseFloat(w.value)) addMessage(messages, w, q);
                                                                        } else if (w.condition == '==') {
                                                                            if (value == w.value) addMessage(messages, w, q);
                                                                        } else if (w.condition == '>') {
                                                                            if (parseFloat(value) > parseFloat(w.value)) addMessage(messages, w, q);
                                                                        } else if (w.condition == '>=') {
                                                                            if (parseFloat(value) >= parseFloat(w.value)) addMessage(messages, w, q);
                                                                        } else if (w.condition == 'in') {

                                                                        } else if (w.condition == 'between') {
                                                                            if (parseFloat(value) > parseFloat(w.value) && parseFloat(value) < parseFloat(w.value_high)) addMessage(messages, w, q);
                                                                        } else if (w.condition == 'group') {
                                                                            if (!groups[w.message]) {
                                                                                let c = 0;

                                                                                for (let n of w.group_ids) {
                                                                                    if (w.value_high == 'high' && questionRisk[n] && questionRisk[n].severity == 'high') {
                                                                                        c++;
                                                                                    } else if (w.value_high == 'moderate' && questionRisk[n] && (questionRisk[n].severity == 'high' || questionRisk[n].severity == 'moderate')) {
                                                                                        c++;
                                                                                    }
                                                                                }
                                                                                if (c >= parseFloat(w.value)) {

                                                                                    addMessage(messages, w);
                                                                                    groups[w.message] = true;
                                                                                }else{
                                                                                    questionRisk[w.id] = false;
                                                                                }
                                                                            }

                                                                        }
                                                                    }

                                                                    if (messages.length) {
                                                                        return messages;
                                                                    }
                                                                    else return null;
                                                                }
                                                                return null;
                                                            }(question)}
                                                            {...layouts[question.category + "In"]}
                                                            validateTrigger='onBlur'
                                                        >
                                                            {makeItem(question)}
                                                        </Form.Item>
                                                    </Form.Item>
                                                ) : null;
                                                setTimeout(function(){
                                                    updateRisk();
                                                },1);
                                                return resp;
                                            }}
                                        </Form.Item>

                                    </div>
                                )}


                                {props.steps &&
                                <div style={{display:"flex", paddingTop:"20px"}}>
                                    {(currentStep != 0) &&
                                    <Button htmlType="button" shape="round" type="text" onClick={() => setCurrentStep(currentStep - 1)}>
                                        <i className="fas fa-chevron-left"/>&nbsp;Previous
                                    </Button>
                                    }
                                    {(currentStep != 3) &&
                                    <Button htmlType="button" style={{marginLeft: "auto"}} shape="round" type="primary" className="btn-grey" onClick={() => setCurrentStep(currentStep + 1)}>
                                        Next&nbsp;<i className="fas fa-chevron-right"/>
                                    </Button>
                                    }
                                    {!isEdit && currentStep == 3 &&
                                        <div style={{marginLeft: "auto", textAlign:"right"}}>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                            {failedValidation>0 && <div
                                                style={{color: "red"}}>There are {failedValidation} unanswered questions</div>}
                                        </div>
                                    }
                                </div>
                                }
                            </div>
                        })}
                    </div>

                    {(isEdit || !props.steps) &&
                    <div style={{display: "flex", paddingTop: "50px"}}>
                        <Form.Item style={{marginLeft: "auto"}}>
                            <Button type="primary" htmlType="submit" style={{marginLeft: "auto"}}>
                                Save
                            </Button>
                        </Form.Item>
                    </div>
                    }
                </>
                }



            </Form>
        </>
    )
}

