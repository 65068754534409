import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useToken from '../../hooks/useToken';

import API from '../../components/api';
import RegisterForm from '../../forms/register';

import {
	Form,
	Input,
	Tooltip,
	Cascader,
	Select,
	Row,
	Col,
	Checkbox,
	Button,
	AutoComplete,
} from 'antd';


import { QuestionCircleOutlined } from '@ant-design/icons';


export default function CreateAccount() {

	return(
		<div className="content_inner">

				<RegisterForm/>

		</div>
	)
}

