import React, {useEffect, useState} from 'react';
import CheckInForm from "../../forms/CheckIn"



export default function CheckIn(props) {

    let id = props.match.params.checkinid;
    if(id == 'new') id = null;

    return(
        <div className="content_inner">
            <CheckInForm id={id} clientId={props.match.params.id}/>
        </div>
	);
}