import React, { useEffect,useContext  } from "react";
import { Avatar,Button } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import SearchTable from "../../components/SearchTable";

export default function Clients(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([<span><i className="fas fa-users"></i> Coaches</span>]);
	}, []);

	const columns = [
        {
            title: "Details",
            responsive: ["xs"],
            render: (record) => (
                <React.Fragment>
                    {record.name}
                    <br />
                    {record.email}
                    <br />
                    {record.mobile}
                </React.Fragment>
            )
        },
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => {return (!a.name)?-1:a.name.localeCompare(b.name)},
            responsive: ["sm"],
        	align:'left',
            render: (text, record) => (
                <div>
                    <span><Avatar src={(record.image)?record.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/></span>
                    <span style={{paddingLeft:"20px"}}>{record.name}</span>
                </div>
            ),
		},
		{
			title: 'Email',
			key: 'email',
            dataIndex: 'email',
            sorter: (a, b) => {return (!a.email)?-1:a.email.localeCompare(b.email)},
            responsive: ["sm"],
			align:'left'
		},
		{
			title: 'Mobile',
			key: 'mobile',
            dataIndex: 'mobile',
            sorter: (a, b) => a.mobile - b.mobile,
            responsive: ["sm"],
		},
		{
			title: 'Actions',
            key: 'actions',
            render: (text, record) => (
				<div>
					<span><NavLink to={"/coach/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
		},
	];

	return (
        <div className="content_inner">
            <SearchTable columns={columns} url="/coaches"/>
        </div>
	);
};