import React, {useEffect, useState} from 'react';
import {
    List
} from 'antd';

import API2 from "../../components/api2";


export default function Contraindications(props) {
    const AP = API2();

    const [warnings, setWarnings] = useState(props.warnings);

    useEffect(() => {
        if(!props.warnings){
            AP.get('/client/'+props.match.params.id+'/overview')
                .then(function(res){
                    setWarnings(res.warnings);
                });
        }
    }, []);

    if(!warnings) return "";

    return <>
        <List
            className="antList1"
            header={props.warnings?"Contraindication":""}
            bordered
            dataSource={Object.keys(warnings)}
            renderItem={(item,k) => (
                <List.Item key={k}>
                    <span>
                        <div style={{fontWeight:"bold"}}>{warnings[k].message}</div>
                        {warnings[k].recommendation &&
                        <div style={{paddingLeft:"40px"}}>{warnings[k].recommendation}</div>
                        }
                    </span>
                </List.Item>
            )}
        />
    </>;
}