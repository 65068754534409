import React, {useEffect, useState} from 'react';
import useToken from '../hooks/useToken';
import {NavLink} from "react-router-dom";

import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import API2 from "../components/api2";




async function loginUser(credentials) {



	return fetch('http://metabolichealth.local/api/login', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(data => data.json())
}


export default function Login() {
	const AP = API2();
	const [form] = Form.useForm();
	const { user, setUser } = useToken();
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();

	const handleSubmit = async ( values: any) => {
		AP.post('/login',values)
			.then(function(res){
				setUser(res.user, res.access_token);
				window.location.replace("/dashboard");
			})
			.catch(function (error) {
				form.setFields([
					{
						name: 'password',
						errors: ['Username or Password is incorrect'],
					},
				]);
			});
	}

	return(
		<div className="content_inner">

			<Form
				form={form}
				name="normal_login"
				className="login-form"
				initialValues={{remember: true}}
				onFinish={handleSubmit}
				style={{ width:"290px",margin:"auto"}}
			>
				<Form.Item
					name="username"
					rules={[{required: true, message: 'Please input your Username!'}]}
				>
					<Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[{required: true, message: 'Please input your Password!'}]}
				>
					<Input
						prefix={<LockOutlined className="site-form-item-icon"/>}
						type="password"
						placeholder="Password"
					/>
				</Form.Item>
				<Form.Item>
					<Form.Item name="remember" valuePropName="checked" noStyle>
						<Checkbox>Remember me</Checkbox>
					</Form.Item>

                    <NavLink exact to="/forgotPassword" className="login-form-forgot" activeClassName="current">
                        <span>Forgot password</span>
                    </NavLink>
				</Form.Item>

				<Row>
					<Col style={{marginLeft:"auto"}}>
						<Form.Item>
							<Button type="primary" htmlType="submit" className="login-form-button">
								Log in
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

