import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import { Layout, Menu, Drawer,Button } from 'antd';
import {
	WifiOutlined,
	DatabaseOutlined,
	CameraOutlined,
	CreditCardOutlined,
	TeamOutlined,
	ControlOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons';
import useToken from "../hooks/useToken";
import useWindowDimensions from "../components/useWindowDimensions";




//import './sider.less';
//import logo from '../../public/image/Enhance-MHS.jpg';


const { Sider } = Layout;
const { SubMenu } = Menu;


export default function SiderNav(props) {
	const { user, setUser } = useToken();
	const [collapsed, setCollapsed] = useState(false);
    const [visible, setVisible]     = useState(false);

    const { height, width } = useWindowDimensions();

    var showDrawer = () => {
        setVisible(true);
    };
    var onClose = () => {
        setVisible(false);
    };

	var onCollapse = collapsed => {
		setCollapsed(collapsed);
	};

    let href=window.location.href.split('/');
    href.shift();
    href.shift();
    href.shift();

    let selectedSubMenu = [];

    if(href.length>1){
        selectedSubMenu = [href[0]];
        href = [href[1]];
	}

	function getMenu(){
    	return <Menu
            style={{ width: (collapsed)?80:200 }}
            defaultSelectedKeys={href}
            defaultOpenKeys={selectedSubMenu}
            mode="inline"
            theme="light"
        >
            <Menu.Item key="dashboard" icon={<i className="fas fa-chart-line"/>}>
                <NavLink to="/dashboard" activeClassName="current">
                    Dashboard
                </NavLink>
            </Menu.Item>
            {user.type == "coach" &&
			<>
                {false &&
                <Menu.Item key="clients" icon={<i className="fas fa-users"/>}>
                    <NavLink to="/clients" activeClassName="current">
                        Clients
                    </NavLink>
                </Menu.Item>
                }

                <Menu.Item key="companies" icon={<i className="fas fa-mobile-alt"></i>}>
                    <NavLink to="/companies" activeClassName="current">
                        Linked Apps
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="dataExport" icon={<i className="fas fa-file-export"></i>}>
                    <NavLink to="/dataExport" activeClassName="current">Data Export</NavLink>
                </Menu.Item>
			</>
            }


            {user.type == "client" &&
                <>
                    <Menu.Item key="coaches" icon={<i className="fas fa-users"/>}>
                        <NavLink to="/coaches" activeClassName="current">
                            Coaches
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="checkins" icon={<i className="far fa-calendar-check"/>}>
                        <NavLink to="/checkins" activeClassName="current">
                        Check In
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="/bodyComposition" icon={<i className="far fa-calendar-check"/>}>
                        <NavLink to="/body-compositions" activeClassName="current">
                            Body Composition
                        </NavLink>
                    </Menu.Item>
                </>
            }

            {user.type == "admin" &&
            <Menu.Item key="users" icon={<i className="fas fa-running"/>}>
                <NavLink to="/users" activeClassName="current">
                    Users
                </NavLink>
            </Menu.Item>
            }

            {(user.type == "api" || user.type == "admin") &&
			<>
                <SubMenu key="api" icon={<span className="anticon"><i className="fas fa-book"/></span>} title="API">
                    <Menu.Item key="key">
                        <NavLink to="/api/key" activeClassName="current"><i className="fas fa-key"/>&nbsp;Access Keys</NavLink>
                    </Menu.Item>
					<Menu.Item key="documentation">
						<NavLink to="/api/documentation" activeClassName="current"><i className="fas fa-info-circle"/>&nbsp;Documentation</NavLink>
					</Menu.Item>
                </SubMenu>
                <Menu.Item key="iframe">
                    <NavLink to="/api/iframe" activeClassName="current"><i className="fas fa-crop-alt"/>&nbsp;Iframe Access</NavLink>
                </Menu.Item>
                <SubMenu key="email" icon={<span className="anticon"><i className="fas fa-envelope"/></span>} title="Email">
                    <Menu.Item key="history">
                        <NavLink to="/email/history" activeClassName="current">History</NavLink>
                    </Menu.Item>
                    <Menu.Item key="templates">
                        <NavLink to="/email/templates" activeClassName="current">Templates</NavLink>
                    </Menu.Item>
                </SubMenu>
			</>
            }



        </Menu>
	}

	return (

		<>
			{width<768 &&
            <>
                <Button className="barsMenu" type="default" onClick={showDrawer} style={{position:"fixed", top:"17px",left:"10px",zIndex:"99999"}}>
                    <i className="fas fa-bars"/>
                </Button>
                <Drawer
                    title="Navigation"
                    placement="left"
                    closable={true}
                    onClose={onClose}
                    visible={visible}
					className="navigation-drawer"
					width="200px"
                >
                    {getMenu()}
                </Drawer>
			</>
            }
            {width >= 768 &&
				<Sider collapsible collapsed={collapsed} onCollapse={onCollapse} theme="light" style={{backgroundColor:"#e4e8ef"}}>
					<div style={{display:'flex',backgroundColor:"white"}}>
						<img className="logo" src="/images/logo.png" style={{padding:"2px", height:"64px", margin:'auto'}}/>
					</div>
					{getMenu()}
				</Sider>
            }


		</>
	);
}