import React, { useState, useEffect  } from "react";
import useToken from "../hooks/useToken";
import axios from 'axios';


class apiCall {

    constructor(token) {
        this.token = token;
        this.response = null;
    }

    get(url){
        this.method = 'get';
        this.url = url;
        this.data = null;
        return this.run();
		this.progress = null;
    }
	post(url,data){

		this.method = 'post';
        this.url = url;
        this.data = data;
        return this.run();
	}
    patch(url,data){
        this.method = 'patch';
        this.url = url;
        this.data = data;
        return this.run();
    }
	put(url,data){
		this.method = 'put';
		this.url = url;
		this.data = data;
		return this.run();
	}
	file(url){
        this.method = 'get';
        this.url = url;
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Response-Type': 'blob'
        };
        let resp = this.run();
        let self = this;
        resp.then(function (res) {
            let headers = self.response.headers;
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            const contentDisposition = headers['content-disposition'];
            const fileName = contentDisposition.split("filename=")[1];

            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }
    options(url,data){
        this.method = 'options';
        this.url = url;
        this.data = data;
        return this.run();
    }


	run() {
        let headers;
        let self = this;
        if (this.headers){
            headers = this.headers;
        }else{
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        if(this.token){
            headers['Authorization'] = 'Bearer '+this.token
        }else{
            let queryParams = new URLSearchParams(window.location.search);
            let jwt = queryParams.get('jwt');
            if(jwt){
                headers['Authorization'] = 'Bearer '+jwt;
            }
        }

		return axios.request({
			method: this.method,
			url: process.env.REACT_APP_API_ENDPOINT+this.url,
			data: this.data,
			headers: headers,
			onUploadProgress: (p) => {
				if(this.progress){
					this.progress(p.total);
				}
			}
		}).then (data => {
            self.response = data;
			return data.data;
		});
	}
}



export default function API2() {

		const {token} = useToken();

		return new apiCall(token);
};