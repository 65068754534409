import React, {Component, useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import BreadCrumbContext from "../contexts/breadCrump";
import API2 from "../components/api2";
import {
    Form,
    Input,
    Button,
    Card,
    Avatar,
    Progress,
    Tooltip,
    Table
} from 'antd';
import ClientsTable from "../table/Clients";
import useToken from "../hooks/useToken";
import Moment from 'react-moment';

//navigator.clipboard.writeText('Copy this text to clipboard')

export default function Dashboard() {
    const [data, setData] = useState(false);
    const AP = API2();
    const [form] = Form.useForm();
    const history = useHistory();
    const { user, setUser } = useToken();

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([<span><i className="fas fa-home"></i> Dashboard</span>]);
	}, []);

    useEffect(() => {
        AP.get('/dashboard')
            .then(function (res) {
                setData(res);
                if(res.invite_url) res.invite_url = window.location.origin+res.invite_url;
                form.setFieldsValue(res);
            });
    }, []);

	const checkinColumns = [
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
			sorter: (a, b) => {return (!a.date)?-1:a.date.localeCompare(b.date)},
			align:'left',
			render: (text, record) => (
				<div>
					<Moment format="DD/MM/YYYY" unix>{record.date}</Moment>
				</div>
			)
		}
	];

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    const riskColor = {
        "high":"red",
		"moderate":"orange",
		"low":"blue",
		"":"black"
    }

    return (
        <div className="content_inner">
            {data &&
            <>
                {data.active_client_count>0 &&
                <div style={{display: "flex", textAlign: "center", justifyContent: "center", flexWrap: "wrap"}}>
                    <div style={{width: 150}}>
						<Tooltip title={<>
							<div>Total Clients: {data.total_client_count}</div>
							<div>Active Clients: {data.active_client_count}</div>
                        </>}>
                            <Progress
                                type="circle"
                                percent={(data.active_client_count/data.total_client_count)*100}
                                format={percent => <span style={{color: "black"}}>{data.active_client_count}</span>}
                                width={80}
                                strokeColor="green"
                            />
                            <div>Active Clients</div>
						</Tooltip>
                    </div>
                    <div style={{width: 150}}>
                        <Progress
                            type="circle"
                            percent={data.completed_percent}
                            format={percent => percent + '%'}
                            width={80}

                            trailColor={(data.active_client_count) ? null : "lightgrey"}
                        />
                        <div>R.A Completion Rate</div>
                    </div>
                    <div style={{width: 150}}>
                        <Tooltip title={<>
                            <div>{data.risk_percent.high}% high</div>
                            <div>{data.risk_percent.moderate}% moderate</div>
                            <div>{data.risk_percent[""]}% low</div>
                        </>}>
                            <Progress
                                percent={(data.risk_percent[""] + data.risk_percent.moderate)}
                                success={{percent: data.risk_percent[""]}}
                                type="circle"
                                width={80}
                                strokeColor="orange"
                                trailColor={(data.active_client_count) ? "red" : "lightgrey"}
                                format={percent => ''}
                            />
                        </Tooltip>
                        <div>Risk Percents</div>
                    </div>
                </div>
                }
                <div>
                    {data.invite_url &&
                    <div style={{paddingTop:"50px"}}>
                        <h3>Invite URL</h3>
                        <Input.Search value={  window.location.origin+data.invite_url} className="readOnly" readOnly="readOnly" enterButton={<button className="ant-btn ant-btn-primary ant-input-search-button"><i className="far fa-copy"/></button>} onSearch={function(){navigator.clipboard.writeText( data.invite_url)}} />
                    </div>
                    }

                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div style={{flex:999}}>
                            {data.latest_risk_assessment &&
                                <div style={{maxWidth:"270px"}}>
                                    <h3>Coach</h3>
                                    <div style={{ minWidth:270,maxWidth:400,margin:"0 20px 20px 0",flex:"1" }}>
                                        <div>
											<Card>
												<p>
													<div style={{display:"flex"}}>
														<div style={{paddingRight:"10px"}}>
															<Avatar src={(data.latest_risk_assessment.coach.profile_image)?data.latest_risk_assessment.coach.profile_image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/>
														</div>
														<div>
															<div>{data.latest_risk_assessment.coach.name}</div>
															<div>{data.latest_risk_assessment.coach.mobile}</div>
															{data.latest_risk_assessment.risk &&
															<div style={{display: "flex"}}>
																<div>Risk:</div>
																<div
																	style={{color: riskColor[data.latest_risk_assessment.risk]}}>&nbsp;{data.latest_risk_assessment.risk.toUpperCase()}</div>
															</div>
															}
														</div>
													</div>
												</p>
                                            </Card>
                                        </div>

										<div style={{textAlign:"center",paddingTop:20}}>
											<Button type="primary" onClick={()=>history.push("/coach/"+data.latest_risk_assessment.coach.id+"/riskAssessment")}><i className="fas fa-clipboard-list"/>&nbsp;Risk Assessment Form</Button>
										</div>
                                    </div>
                                </div>
                            }
                        </div>
						{user.type=="client" &&
                        <div style={{flex:"1 1 200px"}}>
							<h3>Check-In</h3>

							<Table
                                dataSource={data.checkins}
                                columns={checkinColumns}
                                //loading={loading}
                                tableLayout="fixed"
                                pagination={false}
							/>

							<div style={{textAlign:"center",paddingTop:20}}>
                                <div>
                                    <Button type="primary" onClick={()=>history.push("/checkin/new/")}><i className="fas fa-clipboard-list"/>&nbsp;Add Check-In</Button>
                                </div>
							</div>
                        </div>
						}
                    </div>

                </div>
            </>
            }


            {user.type=="coach" &&
                <div style={{paddingTop:"50px"}}>
                    <h3>Clients</h3>
					<ClientsTable/>
                </div>
            }
        </div>
    );
}