import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {
    Form,
    Input,
    Select,
    Button
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import API from "../components/api";
import useToken from "../hooks/useToken";
import {notification} from "antd/lib/index";


const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function InviteClient(props) {
    const [form] = Form.useForm();
    const { user, setUser } = useToken();
    const [resp, isLoading, isError, doPost] = API('POST','/client');
    const [apiErrors, setApiErrors] = useState(false);
    const history = useHistory();


    useEffect(() => {
        if(!resp) return false;
        if(isError){
            setApiErrors(resp.errors);
        }else {

            history.push("/client/"+resp.id);
        }
    }, [resp]);

    function saveForm(data){
        doPost(data);

        notification.success({
            message: `Saved`,
            placement: 'bottomRight',
            duration:1,
            closeIcon : (<div></div>)
        });


    }

    useEffect(() => {
        if(!apiErrors) return true;

        let errorList = {};
        for(let error of apiErrors){
            if(!errorList[error.input]) errorList[error.input] = [];
            errorList[error.input].push(error.message);
        }

        for (let [input, errors] of Object.entries(errorList)) {
            form.setFields([
                {
                    name: input,
                    errors: errors,
                },
            ]);
        }
    },[apiErrors]);

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={saveForm}
            initialValues={{
                residence: ['zhejiang', 'hangzhou', 'xihu'],
                prefix: '86',
            }}
            scrollToFirstError
            style={{ width:"800px",margin:"auto"}}
        >
            <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                    {
                        required:true,
                        message:'Please input your first name!',
                        whitespace:true
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                    {
                        required:true,
                        message:'Please input your last name!',
                        whitespace:true
                    }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                    Invite
                </Button>
            </Form.Item>
        </Form>
    )
}

