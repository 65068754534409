import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Tabs,
    Form,
    Input,
    Select,
    Slider,
    Button,
    DatePicker,
    Divider,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";
import Moment from 'react-moment';
import useToken from "../hooks/useToken";



const { TabPane } = Tabs;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function BodyCompositionForm(props) {

    const { user, setUser } = useToken();
    const [form] = Form.useForm();
    const [checkin, setCheckin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {

        setBreadCrumbs([
            (props.clientId &&
                <>
                    <NavLink to="/clients"><i className="fas fa-users"></i> Clients </NavLink> / &nbsp;
                    <NavLink to={"/client/"+props.clientId}><span><UserOutlined/> Client</span></NavLink> / &nbsp;
                    <NavLink to={"/client/"+props.clientId+"/bodyCompositions"}><span><UserOutlined/> Body Compositions</span></NavLink>
                </>)
                || <NavLink to="/body-compositions"><span><i className="far fa-calendar-check"/> Body Compositions</span></NavLink>,
            (props.id)?<Spin size="small"/>:<span>Create</span>
        ]);
    }, []);



    const AP = API2();
    useEffect(() => {
        if(props.id) {

            let url;
            if(props.clientid){
                url = '/client/'+props.clientid+'/body-composition/'+props.id;
            }else{
                url = '/body-composition/'+props.id;
            }

            AP.get(url)
                .then(function (res) {
                    setBreadCrumbs([
                        (props.clientId &&
                            <>
                                <NavLink to="/clients"><i className="fas fa-users"></i> Clients </NavLink> / &nbsp;
                                <NavLink to={"/client/"+props.clientId}><span><UserOutlined/> Client</span></NavLink> / &nbsp;
                                <NavLink to={"/client/"+props.clientId+"/bodyCompositions"}><span><UserOutlined/> Body Compositions</span></NavLink>
                            </>)
                        || <NavLink to="/body-compositions"><span><i className="far fa-calendar-check"/> Body Compositions</span></NavLink>,
                        <span>
                            <Moment format="DD/MM/YYYY" unix>{res.date}</Moment>
                        </span>
                    ]);

                    if (res.date) {
                        res.date = moment(res.date*1000 );
                    }



                    setCheckin(res);
                    form.setFieldsValue(res);

                    setIsLoading(false);
                });
        }
        else{
            let res = {};
            res.date = moment( );
            form.setFieldsValue(res);
        }
    }, []);

    function saveForm(data){
        setIsSaving(true);

        if(props.clientId){
            data.client_id = props.clientId;
        }

        if(data.date){
            data.date = data.date.unix();
        }

        let url = '/body-composition';
        if(props.id) {
            url += '/'+props.id;
        }
        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            history.push("/body-composition/"+res.id);
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });;
    }


    const measurementsForm = <>
        <Form.Item
            name={["measurements", 'upper_arm']}
            label="Upper Arm (flexed)"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["measurements", 'chest']}
            label="Chest"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["measurements", 'waist']}
            label="Waist"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["measurements", 'hips']}
            label="Hips"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["measurements", 'thigh']}
            label="Thigh"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["measurements", 'calf']}
            label="Calf"
        >
            <Input />
        </Form.Item>
    </>;
    const skinFoldsForm = <>
        <Form.Item
            name={["skin_folds", 'pec']}
            label="Pec"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["skin_folds", 'tricep']}
            label="Tricep"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["skin_folds", 'sub_scap']}
            label="Sub Scap"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["skin_folds", 'mid_axilla']}
            label="Mid Axilla"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["skin_folds", 'supra_illiac']}
            label="Supra Illiac"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["skin_folds", 'umbillical']}
            label="Umbillical"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={["skin_folds", 'quad']}
            label="Quad"
        >
            <Input />
        </Form.Item>
    </>;











    return(
        <Form
            {...formItemLayout}
            form={form}
            name="checkin"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
			className={props.clientId?"form-compressed":""}
        >

            <Form.Item
                name="date"
                label="Date"


                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <DatePicker format="DD/MM/YYYY"
                            style={{width:"100%"}}
                            placeholder="DD/MM/YYYY"/>
            </Form.Item>

            <Form.Item
                name="weight"
                label="Average weekly weight"
            >
                <Input />
            </Form.Item>

            <div style={{display:"flex",justifyContent:"center",padding:"15px 0 30px 0"}}>
                <div style={{borderTop:"1px solid grey",width:300}}></div>
            </div>


            {props.clientid &&
                <div style={{ padding: "0 80px", marginBottom:"40px"}}>
                    <Tabs defaultActiveKey="1" style={{marginTop:"50px"}} centered>
                        <TabPane tab="Girth Measurements" key="1">
                            {measurementsForm}
                        </TabPane>
                        <TabPane tab="Skin Folds" key="2">
                            {skinFoldsForm}
                        </TabPane>
                    </Tabs>
                </div>
            }

            {!props.clientid &&
            <div style={{ padding: "0 80px", marginBottom:"40px"}}>

                <div style={{display:"flex",flexWrap:"wrap"}}>
                    <div style={{minWidth:500,flex:1}}>
                        <h3>Girth Measurements</h3>
                        {measurementsForm}
                    </div>
                    <div style={{minWidth:500,flex:1}}>
                        <h3>Skin Folds</h3>
                        {skinFoldsForm}
                    </div>
                </div>

            </div>
            }


            <div style={{display:"flex",justifyContent:"right", gap:10}}>
                <NavLink to={(user.type == "coach")?"/client/"+props.clientId+"/bodyCompositions":"/body-compositions"}>
                    <Button>Cancel</Button>
                </NavLink>

                <Button type="primary" htmlType="submit" loading={isSaving}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
	);
}