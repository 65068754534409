import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import { Tabs , Spin, Divider, Form, Input,Switch, Button,Select, Table } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import RiskAssessment from '../../forms/riskAssessment'
import API2 from "../../components/api2";
import Moment from 'react-moment';
import SearchTable from "../../components/SearchTable";
import {notification} from "antd/lib/index";
import BodyCompositions from "../BodyComposition/BodyCompositions";
import CheckinsInner from "../CheckIn/CheckInsInner";
import Contraindications from "../../components/page/Contraindications";


const { TabPane } = Tabs;
const { Option } = Select;


export default function Client(props) {

	let id = props.match.params.id;
	const [client, setClient] = useState(false);
    const [overview, setOverview] = useState(false);
	const [form] = Form.useForm();
    const AP = API2();
	const setBreadCrumbs = useContext(BreadCrumbContext);
    const [active, setActive] = useState(0);

	const [checkins, setCheckins] = useState([]);
	const [checkinsSummary, setCheckinsSummary] = useState([]);
	const [checkinsSummaryColumns, setCheckinsSummaryColumns] = useState({});


    const [showWeightLine, setShowWeightLine] = useState(true);
    const [showFoldsLine, setShowFoldsLine] = useState(true);
    const [showGirthsLine, setShowGirthsLine] = useState(true);
    const [showFatLine, setShowFatLine] = useState(true);
    const [showLBMLine, setShowLBMLine] = useState(true);

	useEffect(() => {

        AP.get('/client/'+id)
            .then(function(res){
                setClient(res);
                setActive(res.active);
                form.setFieldsValue(res);
            });

        AP.get('/client/'+id+'/overview')
            .then(function(res){
                setOverview(res);
            });

	}, []);

    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/clients"><i className="fas fa-users"></i> Clients </NavLink>,
            (client)?<span><UserOutlined/> {client.name}</span>:<Spin size="small" />
        ]);
    }, [client]);

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		},
	};

    let defaultTab = props.match.params.tab?props.match.params.tab:"overview";

    const riskColor = {
        'high':'red',
        'moderate':'orange',
        'low':'green'
    };

	const checkInColumns = [
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
			sorter: true,
			align:'left',
			render: (text, record) => (
				<div>
					<Moment format="DD/MM/YYYY" unix>{record.date}</Moment>
				</div>
			)
		},
		{
			title: 'Average Weekly Weight',
			key: 'weight',
			dataIndex: 'weight',
			sorter: true,
			align:'left',
		},
		{
			title: 'Actions',
			key: 'actions',
			width: '200px',
			render: (text, record) => (
				<div>
					<span><NavLink to={"/client/"+id+"/checkin/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
		},
	];

	useEffect(() => {

		let columns = [
			{
				title: 'Question',
				key: 'question',
				dataIndex: 'question',
				sorter: (a, b) => {return (!a.question)?-1:a.question.localeCompare(b.question)},
				align: 'left',
                width: '200px'
			}
		];

		let dark = true;
		for(let c of checkins){
			columns.push({
				title: <div>
                    <Moment format="DD/MM/YYYY" unix>{c.date}</Moment>
                    <NavLink to={"/client/"+id+"/checkin/"+c.id} style={{position:"absolute", right:0}}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink>
                </div>,
				key: c.id,
				dataIndex: c.id,
				align: 'center',
                width: '350px',
                className: (dark?'darkColumn':'')
			});
			dark = !dark;
		}

		const nameMap = {
            "weight": "Weight",
            "rpe": "RPE for the week",
            "sessions": "Number of sessions completed out of planned",
            "percentage": "Compliance percentage for the week",
            "considerations": "Compliance considerations",
            "macros_protein": "Average Weekly Protein",
            "macros_carb": "Average Weekly Carbs",
            "macros_fat": "Average Weekly Fat",
            "macros_fibre": "Average Weekly Fibre",
            "sleep_hours": "Average hours sleep",
            "sleep_quality": "Average sleep quality",
            "steps": "Average Daily Steps",
            "water": "Average Daily litres of water drunk",
            "energy": "Average Weekly Kcals",
            "hunger": "Hunger",
            "stress": "Stress"
        };

		let rowData = [];
		for(let f of [
		    "title.Weight","weight","comments.weight",
            "title.Training","rpe","sessions","comments.training","comments.client_training",
            "title.Nutrition","percentage","considerations","macros_protein","macros_carb","macros_fat","macros_fibre","comments.nutrition","comments.client_nutrition",
            "title.Lifestyle","sleep_hours","sleep_quality","steps","water","comments.sleep","comments.client_sleep",
            "title.Other","energy","hunger","stress","comments.other","comments.client_other",
        ]){

		    const isComment = f.substr(0,9) == "comments.";
            const isClientComment = f.substr(0,16) == "comments.client_";
            const isTitle   = f.substr(0,6) == "title.";

            let question = '';
            if(isClientComment){
                question = 'Client Comment';
            }else if(isComment){
                question = 'Coach Comment';
            }else if(isTitle){
                question = f.substr(6);
            }else{
                question = nameMap[f];
            }

			let row =  {
				"question":  question,
                "isComment": isComment,
                "isTitle":   isTitle
			};

			for(let c of checkins){
			    if(isTitle){
                    row[c.id] = '';
                }
			    else if(isComment){
			        if(c.comments) {
                        row[c.id] = c.comments[f.substr(9)];
                    }else{
                        row[c.id] = '';
                    }
                }
				else if(f=="weight"){
					row[c.id] = c[f];
				}
                else if(["sleep_quality","energy","hunger","stress"].indexOf(f) != -1){
			        if(c.compliance[f]) {
                        row[c.id] = c.compliance[f] + "/5";
                    }else{
                        row[c.id] = "";
                    }
                }
				else {
					row[c.id] = c.compliance[f];
				}
			}

			rowData.push(row);
		}

		setCheckinsSummary(rowData);
		setCheckinsSummaryColumns(columns);

	}, [checkins]);

/*
    function saveForm(data){

        if(data.dob){
            data.dob = data.dob.unix();
        }

        //get profile image value
        data['profile_image_id'] = null;
        for(let f of files){
            if(f.attachment_id) data['profile_image_id'] = f.attachment_id;
            else data['profile_image_id'] = f.uid;
        }

        if(paymentMethod){
            if(paymentMethod=='deleted'){
                data.payment_method = 'deleted';
            }else{
                data.payment_method = true;
            }
        }else{
            data.payment_method = false;
        }

        AP.post('/profile',data).then(function(resp){
            setUser(resp);
        });

        notification.success({
            message: `Saved`,
            placement: 'bottomRight',
            duration:1,
            closeIcon : (<div></div>)
        });


    }
*/
    let timeout;
    function onChangeForm(changed,data){

        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(function(){
            AP.post('/client/'+id,data)
                .then(function(res){
                    notification.success({
                        message: `Saved`,
                        placement: 'bottomRight',
                        duration:1,
                        closeIcon : (<div></div>)
                    });
                });
        }, 1000);
    }

    function toggleLines($line){
        if($line == 'weight') setShowWeightLine(!showWeightLine);
        if($line == 'folds') setShowFoldsLine(!showFoldsLine);
        if($line == 'girth') setShowGirthsLine(!showGirthsLine);
        if($line == 'fat') setShowFatLine(!showFatLine);
        if($line == 'lmb') setShowLBMLine(!showLBMLine);
    }

    function filterCheckins(data){
        setCheckins(data);
        return data;
    }

    return (

        <div className="content_inner">
			<Tabs
                defaultActiveKey={defaultTab}
                centered
                onChange={key => {
                    props.history.replace({ pathname: "/client/"+id+"/"+key});
                }}
            >
				<TabPane tab="OVERVIEW" key="overview">
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div style={{flex:"1",minWidth:"300px"}}>
                            <Form
                                {...formItemLayout}
                                form={form}
                                //onFinish={saveForm}
                                onValuesChange={onChangeForm}
                                style={{ margin:"auto"}}
                            >
                                <Form.Item
                                    name="name"
                                    label="Name"
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label="E-mail"
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    name="mobile"
                                    label="Mobile"
                                >
                                    <Input/>
                                </Form.Item>

								<Form.Item
									name="gender"
									label="Gender"
									rules={[
										{
											required: true,
											message: 'Please select a gender',
										}
									]}
								>
									<Select
										placeholder="Select a option and change input text above"
									>
										<Option value="male">Male</Option>
										<Option value="female">Female</Option>
									</Select>
								</Form.Item>

                                <Form.Item
                                    name="active"
                                    label="Status"
                                    valuePropName="checked"
                                >
                                    <Switch
                                   //     checked={active}
                                        checkedChildren="Active"
                                        unCheckedChildren="Inactive"
                                        onChange={() => {
                                     //       onFinish();
                                        }}
                                        loading={!client}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                        <div style={{flex:"1",minWidth:"300px",fontSize:"20px", textAlign:"center",alignSelf:"center",padding:"20px 0"}}>
                            <div>
                                {overview && overview.risk && <div>RISK: <span style={{color:((overview)?riskColor[overview.risk]:'black'),textAlign:"center"}}>{(overview.risk_completed_at)?overview.risk.toUpperCase():'-'}</span></div>}
                            </div>
                        </div>
                    </div>


					{overview.warnings &&
                        <Contraindications warnings={overview.warnings} />
					}







				</TabPane>
				<TabPane tab="RISK ASSESSMENT" key="riskAssessment">
					<RiskAssessment client={client} overview={overview}></RiskAssessment>
				</TabPane>
                <TabPane tab="CHECK INS" key="checkins">
                    <CheckinsInner id={id}/>
                </TabPane>
                <TabPane tab="BODY COMPOSITIONS" key="bodyCompositions">
                    <BodyCompositions id={id}/>
                </TabPane>
			</Tabs>
        </div>
	);
}