import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import { Line } from '@ant-design/charts';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";
import Moment from 'react-moment';
import CheckinsInner from "../CheckIn/CheckInsInner";

export default function CheckIns(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="far fa-calendar-check"/> Check Ins</span>
        ]);
	}, []);

    const [graphDensityData, setGraphDensityData] = useState([]);
    const [graphFatData, setGraphFatData] = useState([]);
	const [graphMassData, setGraphMassData] = useState([]);
	const [loadingCheckIns, setLoadingCheckIns] = useState(true);
	const [checkins, setCheckins] = useState([]);
	const [checkinsSummary, setCheckinsSummary] = useState([]);
	const [checkinsSummaryColumns, setCheckinsSummaryColumns] = useState({});
    const AP = API2();


	const columns = [
		{
			title: 'Date',
			key: 'date',
            dataIndex: 'date',
            sorter: true,
        	align:'left',
            render: (text, record) => (
                <div>
                    <Moment format="DD/MM/YYYY" unix>{record.date}</Moment>
                </div>
            )
		},
		{
			title: 'Average Weekly Weight',
			key: 'weight',
            dataIndex: 'weight',
			sorter: true,
			align:'left',
		},
		{
			title: 'Actions',
            key: 'actions',
            width: '200px',
            render: (text, record) => (
				<div>
					<span><NavLink to={"/checkin/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
		},
	];


    useEffect(() => {

        let columns = [
            {
                title: 'Question',
                key: 'question',
                dataIndex: 'question',
                sorter: (a, b) => {return (!a.question)?-1:a.question.localeCompare(b.question)},
                align: 'left',
                width: '200px'
            }
        ];

        let dark = true;
        for(let c of checkins){
            columns.push({
                title: <Moment format="DD/MM/YYYY" unix>{c.date}</Moment>,
                key: c.id,
                dataIndex: c.id,
                align: 'center',
                width: '350px',
                className: (dark?'darkColumn':'')
            });
            dark = !dark;
        }


        const nameMap = {
            "weight": "Weight",
            "rpe": "RPE for the week",
            "sessions": "Number of sessions completed out of planned",
            "percentage": "Compliance percentage for the week",
            "considerations": "Compliance considerations",
            "macros_protein": "Average Weekly Protein",
            "macros_carb": "Average Weekly Carbs",
            "macros_fat": "Average Weekly Fat",
            "macros_fibre": "Average Weekly Fibre",
            "sleep_hours": "Average hours sleep",
            "sleep_quality": "Average sleep quality",
            "steps": "Average Daily Steps",
            "water": "Average Daily litres of water drunk",
            "energy": "Average Weekly Kcals",
            "hunger": "Hunger",
            "stress": "Stress"
        };

        let rowData = [];
        for(let f of [
            "title.Weight","weight","comments.weight",
            "title.Training","rpe","sessions","comments.training","comments.client_training",
            "title.Nutrition","percentage","considerations","macros_protein","macros_carb","macros_fat","macros_fibre","comments.nutrition","comments.client_nutrition",
            "title.Lifestyle","sleep_hours","sleep_quality","steps","water","comments.sleep","comments.client_sleep",
            "title.Other","energy","hunger","stress","comments.other","comments.client_other",
        ]){

            const isComment = f.substr(0,9) == "comments.";
            const isClientComment = f.substr(0,16) == "comments.client_";
            const isTitle   = f.substr(0,6) == "title.";

            let question = '';
            if(isClientComment){
                question = 'Client Comment';
            }else if(isComment){
                question = 'Coach Comment';
            }else if(isTitle){
                question = f.substr(6);
            }else{
                question = nameMap[f];
            }

            let row =  {
                "question":  question,
                "isComment": isComment,
                "isTitle":   isTitle
            };

            for(let c of checkins){
                if(isTitle){
                    row[c.id] = '';
                }
                else if(isComment){
                    if(c.comments) {
                        row[c.id] = c.comments[f.substr(9)];
                    }else{
                        row[c.id] = '';
                    }
                }
                else if(f=="weight"){
                    row[c.id] = c[f];
                }
                else if(["sleep_quality","energy","hunger","stress"].indexOf(f) != -1){
                    if(c.compliance[f]) {
                        row[c.id] = c.compliance[f] + "/5";
                    }else{
                        row[c.id] = "";
                    }
                }
                else {
                    row[c.id] = c.compliance[f];
                }
            }

            rowData.push(row);
        }

        setCheckinsSummary(rowData);
        setCheckinsSummaryColumns(columns);

    }, [checkins]);



    const configDensity = {
        data: graphDensityData,
        height: 100,
        xField: 'date',
        yField: 'value',
        point: {
            size: 5,
            shape: 'diamond',
        },
    };
    const configFat = {
        data: graphFatData,
        height: 100,
        xField: 'date',
        yField: 'value',
        point: {
            size: 5,
            shape: 'diamond',
        },
    };
    const configMass = {
        data: graphMassData,
        height: 100,
        xField: 'date',
        yField: 'value',
        point: {
            size: 5,
            shape: 'diamond',
        },
    };

    function filterCheckins(data){
        setCheckins(data);
    	return data;
	}

	return (
        <div className="content_inner">
            <CheckinsInner/>
        </div>
	);
};