import '../App.css';
import {BrowserRouter, NavLink , Route, Switch} from "react-router-dom";


import ESPage from '../pages/ExpressInterest2'
import RiskAssessment from '../forms/riskAssessment'
import Client from "../pages/Client/Client";
import Contraindications from "../components/page/Contraindications";
import RegisterClient from '../forms/registerClient';

function NoLayout(props) {

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/ei" component={ESPage}/>
                <Route exact path="/iframe/client/:clientId/riskAssessment" component={RiskAssessment}/>
                <Route exact path="/iframe/client/:id/contraindications" component={Contraindications}/>
                <Route exact path="/iframe/join" component={RegisterClient}/>

                <Route exact path="/iframe/coach/:coachId/client/:clientId/riskAssessment" component={RiskAssessment}/>
                <Route exact path="/iframe/client/:clientId/coach/:coachId/riskAssessment" component={RiskAssessment}/>
			</Switch>
		</BrowserRouter>
	);
}

export default NoLayout;



