import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {NavLink} from "react-router-dom";
import API2 from "./api2";
import useWindowDimensions from "./useWindowDimensions";

export default function SearchTable(props) {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const AP = API2();


    let tableId = window.location.pathname;
    if(props.id){
        tableId = props.id+tableId;
    }
    let defaultPageSize = localStorage.getItem(tableId+'-pageSize');
    if(!defaultPageSize) defaultPageSize = 10;

    const [dataSize, setDataSize] = useState(0);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState();
    const [filters, setFilters] = useState();
	const [queryFilters, setQueryFilters] = useState();
    const [sorter, setSorter] = useState();
    const [searchText, setSearchText] = useState('');

    const { height, width } = useWindowDimensions();

    function search(value){
        setPagination(1);
        setSearchText(value);
    }

	function tableChange(p, f, s) {
        setPagination(p);
        setFilters(f);
        setSorter(s);
        setPageSize(p.pageSize);
        localStorage.setItem(tableId+'-pageSize',p.pageSize);
    }

    useEffect(() => {
        getData();
    }, [pagination,filters,sorter,searchText]);

	useEffect(() => {
		if(JSON.stringify(props.filters) === JSON.stringify(queryFilters)) return true;
		setQueryFilters(props.filters);
		getData();
	}, [props]);

    //check columns
    useEffect(() => {
        let cols = [];

        myloop:
        for(let c of props.columns){
            if(c.responsive) {

                for (let wi in c.responsive) {
                    let w = c.responsive[wi];
                    if (w == 'max_md' && width > 768) continue myloop;
                    else if (w == 'max_lg' && width > 992) continue myloop;
                    else if (w == 'max_xl' && width > 1200) continue myloop;

                    if(["max_md","max_lg","max_xl"].includes(w)){
                        c.responsive.splice(wi, 1);
                        if(!c.responsive.length) c.responsive = null;


                    }
                }
            }
            cols.push(c);
        }
        setColumns(cols);
    }, [props.columns]);

    function getData(){
        setLoading(true);

        let url = props.url;
        let params = [];

        if(pagination) {
            if(pagination.current)  params.push('page='+pagination.current);
            if(pagination.pageSize) params.push('pageSize='+pagination.pageSize);
        }else{
            params.push('pageSize='+pageSize);
        }
        if(searchText) params.push('search='+searchText);

        if(props.filters){
            for(let k in props.filters){
                params.push(k+"="+props.filters[k]);
            }
        }

        if(params){
            url += "?"+params.join("&");
        }

        AP.get(url)
            .then(function(res){
                if(props.filterData){
                    res.data = props.filterData(res.data);
                }
                setData(res.data);
                setDataSize(res.total_records);
                setLoading(false);
            });
    }

	return (
		<>
            <Input.Search
                placeholder="Search..."
                onSearch={search}
                allowClear={true}
                style={{width:"200px", height:"0px", top:"-40px", position:"relative"}}
            />
            <Table
                dataSource={data}
                loading={loading}
                onChange={tableChange}
                tableLayout="fixed"
                pagination={{
                    pageSize: pageSize,
                    total: dataSize
                }}
                {...props}
            />
		</>
	);
};