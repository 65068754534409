import React, {useContext, useEffect, useState} from 'react';
import ProfileForm from '../forms/profile';
import {NavLink} from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';


import { QuestionCircleOutlined } from '@ant-design/icons';
import BreadCrumbContext from "../contexts/breadCrump";


export default function CreateClientAccount() {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
			<NavLink to="/clients"><i className="fas fa-user"></i> Profile </NavLink>
		]);
	}, []);

	return(
		<div className="content_inner">
			<ProfileForm/>
		</div>
	)
}

