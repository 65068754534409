import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import useToken from './useToken';

import {
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    AutoComplete,
} from 'antd';




export default function PrivateRoute({ component: Component, ...rest }) {

	const { token, setToken } = useToken();

	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page
		<Route {...rest} render={props => (token ? <Component {...props} {...rest}/> :
			<Redirect to="/login" setToken={setToken}/>)} />
	)
}