import React, {useContext, useEffect, useState} from 'react';
import InviteClient from '../../forms/inviteClient';
import {NavLink} from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';

import {
	Form,
	Input,
	Tooltip,
	Cascader,
	Select,
	Row,
	Col,
	Checkbox,
	Button,
	AutoComplete,
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import BreadCrumbContext from "../../contexts/breadCrump";


export default function CreateClientAccount() {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([
			<NavLink to="/clients"><i className="fas fa-users"></i> Clients </NavLink>,
			,<span><UserOutlined/> New</span>
		]);
	}, []);

	return(
		<div className="content_inner">
			<InviteClient/>
		</div>
	)
}

