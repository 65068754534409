import React, { Component } from "react";
import {
    Row,Col,Card,
} from 'antd';

class About extends Component {
	render() {

		return (
            <div className="content_inner">
                <div className="full_width">
                    <div className="full_width_inner">
                        <div className="wpb_row section 0 grid_section" style={{"textAlign":"left"}}>
                            <div className=" section_innerz clearfix">
                                <div className="section_inner_margin clearfix">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                        <h2 style={{"textAlign":"left"}}>METABOLIC HEALTH SYSTEMS</h2>
                                                        <p>&nbsp;</p>
                                                        <p>Metabolic Health Systems provides an online client management
                                                            program&nbsp;tailored towards Coaches, Trainers and Business
                                                            Owners looking to not only manage all their client
                                                            information, measurements, skinfolds, nutrition and exercise
                                                            requirements for goal achievement, but also utilise a
                                                            Dietitian Network for client referral.</p>
                                                        <p>&nbsp;</p>
                                                        <p>Bridging the gap between the current health and fitness
                                                            industry and Dietitians, the MHS&nbsp;allows a seamless and
                                                            streamlined approach to providing clients and members with
                                                            the best of programming, body composition results, and
                                                            Coach/Trainer support.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex",marginTop:40}}>
                            <Card type="inner" title="Year Subscription" style={{ width:300,marginRight:5 }}>
                                <div style={{display:"flex"}}>
                                    <h2 style={{flex:1}}>$179</h2>
                                    <div>21% discount</div>
                                </div>
                            </Card>
                            <Card type="inner" title="Month Subscription" style={{ width: 300,marginLeft:5 }}>
                                <h2>$19</h2>
                            </Card>
                        </div>

                    </div>
                </div>

            </div>
		);
	}
}

export default About;