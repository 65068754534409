import '../App.css';
import {useState} from 'react';
import {BrowserRouter, NavLink , Route, Switch} from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import ExpressInterest from "../pages/ExpressInterest";
import Pricing from "../pages/Pricing";
import SportsNutrition from "../pages/SportsNutrition";
import About from "../pages/About";
import Login from "../pages/Login";
import CreateAccount from "../pages/Coach/createAccount";
import PublicCreateClientAccount from "../pages/Client/PublicCreateAccount";
import Dashboard from "../pages/Dashboard";
import Clients from "../pages/Client/Clients";
import Client from "../pages/Client/Client";
import Footer from "../layout/footer";

import useToken from '../hooks/useToken';


import PrivateRoute from '../hooks/PrivateRoute'
import PublicRoute from '../hooks/PublicRoute'

import { Menu } from 'antd';
import ForgotPassword from "../pages/ForgetPassword/ForgotPassword";
const { SubMenu } = Menu;


function FrontLayout(props) {
	const { token, setToken } = useToken();

	if(token){
		console.log(['hasToken',token]);
	}else{
		console.log(['no token',token]);
	}

    let href=window.location.href.split('/');
    href.shift();
    href.shift();
    href.shift();

    let selectedSubMenu = [];

    if(href.length>1){
        selectedSubMenu = [href[0]];
        href = [href[1]];
    }

	return (
		<BrowserRouter>
				<div class="wrapper_inner">
					<header className="scroll_header_top_area light fixed scrolled_not_transparent header_style_on_scroll page_header">
						<div className="header_inner clearfix">

							<div className="header_top_bottom_holder">
								<div className="header_bottom clearfix" style={{"backgroundColor":"rgb(228, 232, 239)"}}>
									<div className="container" style={{display:"flex", height:"100px"}}>


										<div style={{width:"260px"}}>
											<a href="/" style={{"height":"90px", "visibility":"visible"}}>
												<img className="normal" src="/images/logo_dark.png" alt="Logo" style={{"height":"100%"}}/>
											</a>
										</div>
										<div style={{flex:"1"}}>
                                            <Menu
												defaultSelectedKeys={href}
												mode="horizontal"
                                                theme="light ant-menu-light"
                                                className="main_menu"
												style={{position:"relative", left:"auto", float:"right", margin:"25px"}}
											>
                                                <Menu.Item key="">
													<NavLink exact to="/" activeClassName="current">
														<span>HOME</span>
													</NavLink>
                                                </Menu.Item>
												<Menu.Item key="about">
													<NavLink exact to="/about" activeClassName="current">
														<span>ABOUT</span>
													</NavLink>
												</Menu.Item>
												<Menu.Item key="expressInterest">
													<NavLink exact to="/expressInterest" activeClassName="current">
														<span>EXPRESS INTEREST</span>
													</NavLink>
												</Menu.Item>
												<Menu.Item key="signUp">
													<NavLink exact to="/signUp" activeClassName="current">
														<span>SIGN UP</span>
													</NavLink>
												</Menu.Item>
												<Menu.Item key="login">
													<NavLink exact to="/login" activeClassName="current">
														<span>LOGIN</span>
													</NavLink>
												</Menu.Item>
                                            </Menu>
										</div>

									</div>
								</div>
							</div>
						</div>
					</header>
					<div className="content content_top_margin">
						<Switch>
							<Route exact path="/" component={Home}/>
							<Route path="/about" component={About}/>
							<Route path="/sportsNutrition" component={SportsNutrition}/>
							<Route path="/contact" component={Contact}/>
							<Route path="/pricing" component={Pricing}/>
							<Route path="/expressInterest" component={ExpressInterest}/>
                            <Route path="/join/:id" component={PublicCreateClientAccount}/>

							<PublicRoute path="/signUp" component={CreateAccount}/>
							<PublicRoute path="/signUpTrial" component={CreateAccount}/>
                            <PublicRoute path="/login" component={Login}/>
                            <PublicRoute path="/forgotPassword/:token?" component={ForgotPassword}/>
							<PrivateRoute path="/dashboard" component={Dashboard}/>
							<PrivateRoute path="/clients" component={Clients}/>
							<PrivateRoute path="/client/:id" component={Client}/>


						</Switch>
					</div>
                    <Footer></Footer>
				</div>
		</BrowserRouter>
	);
}

export default FrontLayout;



