import React, {useContext, useEffect, useState} from 'react';
import RegisterForm from '../../forms/registerClient';
import { UserOutlined } from '@ant-design/icons';

import { QuestionCircleOutlined } from '@ant-design/icons';
import API2 from "../../components/api2";
import useToken from "../../hooks/useToken";

export default function PublicCreateClientAccount() {
    const AP = API2();
    const { user, setUser } = useToken();
    const [coach, setCoach] = useState({});

    useEffect(() => {
        try {
            let enc = window.location.pathname.substring(6);
            let dec = atob(enc);
            let coach = JSON.parse(dec);

            if(coach.iv){
                AP.post('/decrypt',{'value':enc})
                    .then(function(res){
                        setUser(res.user, res.access_token);
                        window.location.reload();
                    })
                    .catch(function(res){
                        debugger;
                    });
            }else{
                setCoach(coach);
            }

        }catch(err){

        }
    },[]);

	return(
		<div className="content_inner">
			<RegisterForm coach={coach}/>
		</div>
	)
}

