import React, {useEffect, useState} from 'react';
import BodyCompositionForm from "../../forms/BodyComposition"



export default function BodyComposition(props) {

    let id = props.match.params.bodyCompositionId;
    if(id == 'new') id = null;

    return(
        <div className="content_inner">
            <BodyCompositionForm id={id} clientId={props.match.params.id}/>
        </div>
	);
}