import React from 'react';
import { Layout, Menu, Row, Col } from 'antd';

const { Footer } = Layout;

//import './sider.less';

class FooterNav extends React.Component {

	render() {
		return (
			<Footer style={{ textAlign: 'center' }}>
				<Row>
					<Col style={{textAlign:'left',width:'50%'}}>
						...
					</Col>
					<Col style={{textAlign:'right',width:'50%'}}>
						Copyright Nutrition Research Institute
					</Col>
				</Row>
			</Footer>
		);
	}
}

export default FooterNav;