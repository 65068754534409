import './App.css';
import useToken from './hooks/useToken';

import Frontend from './layout/frontend'
import Backend from './layout/backend'
import 'antd/dist/antd.css';

import NoLayout from "./layout/noend";

function App() {
	const { user, setUser } = useToken();

    let host = window.location.hostname;

    if (window.location.pathname.substring(0, 8) === '/iframe/') { //['/ei'].includes(window.location.pathname)
        return (
            <NoLayout setUser={setUser}/>
        );
    }
    else if (user) {
        return (
            <Backend setUser={setUser}/>
        );
    } else {
        return (
            <Frontend/>
        );
    }
}

export default App;

/*
<NavLink>
			<div>
				<ul className="header">
					<li><NavLink  exact to="/">Home</NavLink ></li>
					<li><NavLink  to="/login">Login</NavLink ></li>
				</ul>
				<div className="content">
					<Route exact path="/" component={Home}/>
					<Route path="/login/:type?" component={Login}/>
				</div>
			</div>
		</NavLink>
 */




