import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Tabs,
    Form,
    Input,
    Select,
    Slider,
    Button,
    DatePicker,
    Divider,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";
import Moment from 'react-moment';
import useToken from "../hooks/useToken";



const { TabPane } = Tabs;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function CheckInForm(props) {

    const { user, setUser } = useToken();
    const [form] = Form.useForm();
    const [checkin, setCheckin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {

        setBreadCrumbs([
            (props.clientId &&
                <>
                    <NavLink to="/clients"><i className="fas fa-users"></i> Clients </NavLink> / &nbsp;
                    <NavLink to={"/client/"+props.clientId}><span><UserOutlined/> Client</span></NavLink> / &nbsp;
                    <NavLink to={"/client/"+props.clientId+"/checkins"}><span><UserOutlined/> Check-Ins</span></NavLink>
                </>)
                ||
                <>
                    <NavLink to="/checkins"><span><UserOutlined/> Checkins</span></NavLink>
                </>,
            (props.id)?<Spin size="small"/>:<span>Create</span>
        ]);
    }, []);

    const AP = API2();
    useEffect(() => {
        if(props.id) {

            let url;
            if(props.clientid){
                url = '/client/'+props.clientid+'/checkin/'+props.id;
            }else{
                url = '/checkin/'+props.id;
            }

            AP.get(url)
                .then(function (res) {

                    setBreadCrumbs([
                        (props.clientId &&
                            <>
                                <NavLink to="/clients"><i className="fas fa-users"></i> Clients </NavLink> / &nbsp;
								<NavLink to={"/client/"+props.clientId}><span><UserOutlined/> Client</span></NavLink> / &nbsp;
								<NavLink to={"/client/"+props.clientId+"/checkins"}><span><UserOutlined/> Check-Ins</span></NavLink>
                            </>)
                        || <NavLink to="/checkins"><span><UserOutlined/> Checkins</span></NavLink>,
                        <span>
                            <Moment format="DD/MM/YYYY" unix>{res.date}</Moment>
                        </span>
                    ]);

                    if (res.date) {
                        res.date = moment(res.date*1000 );
                    }



                    setCheckin(res);
                    form.setFieldsValue(res);

                    setIsLoading(false);
                });
        }
        else{
            let res = {};
            res.date = moment( );
            form.setFieldsValue(res);
        }
    }, []);

    function saveForm(data){

        setIsSaving(true);

        if(props.clientId){
            data.clientId = props.clientId;
        }

        if(data.date){
            data.date = data.date.unix();
        }

        let url = '/checkin';
        if(props.id) {
            url += '/'+props.id;
        }
        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            history.push("/checkin/"+res.id);
        }).catch(function(res){
            setIsSaving(false);

            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });;
    }

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="checkin"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
            labelCol={{ span: 8}}
            wrapperCol={{ span: 16 }}
			className={props.clientId?"form-compressed":""}
        >

            <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                <div style={{flex:1,minWidth:"500px",maxWidth:"898px"}}>
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <DatePicker format="DD/MM/YYYY"
                                    style={{width:"100%"}}
                                    placeholder="DD/MM/YYYY"
                        />
                    </Form.Item>
                </div>
            </div>

            <Divider style={{marginTop:0}}>Weight</Divider>

            <div style={{display:"flex",flexWrap:"wrap"}}>
                <div style={{flex:1,minWidth:"500px"}}>
                    <Form.Item
                        name="weight"
                        label="Average weekly weight"
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div style={{flex:1,minWidth:"400px"}}>
                    {user.type == "client" &&
                    <>
                        {props.id &&
                        <Form.Item
                            name={["comments", 'weight']}
                            label="Coach Comments"
                        >
                            <Input.TextArea rows={4} disabled/>
                        </Form.Item>
                        }
                    </>
                    }
                    {user.type == "coach" &&
                    <>
                        <Form.Item
                            name={["comments", 'weight']}
                            label="Comments"
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </>
                    }
                </div>
            </div>






            <div>

                    <Divider style={{marginTop:0}}>Training</Divider>
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div style={{flex:1,minWidth:"500px"}}>
                            <Form.Item
                                name={["compliance", 'rpe']}
                                label="RPE for the week"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance", 'sessions']}
                                label="Number of sessions completed out of planned"
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div style={{flex:1,minWidth:"400px"}}>
                            {user.type == "client" &&
                            <>
                                <Form.Item
                                    name={["comments", 'client_training']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4}/>
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'training']}
                                    label="Coach Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                            {user.type == "coach" &&
                            <>
                                <Form.Item
                                    name={["comments", 'training']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'client_training']}
                                    label="Client Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                        </div>
                    </div>


                    <Divider style={{marginTop:0}}>Nutrition</Divider>
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div style={{flex:1,minWidth:"500px"}}>
                            <Form.Item
                                name={["compliance", 'percentage']}
                                label="Compliance percentage for the week"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance","considerations"]}
                                label="Compliance considerations"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance","calories"]}
                                label="Average Weekly Kcals"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance","macros_protein"]}
                                label="Average Weekly Protein"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance","macros_carb"]}
                                label="Average Weekly Carbs"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance","macros_fat"]}
                                label="Average Weekly Fat"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                            name={["compliance","macros_fibre"]}
                            label="Average Weekly Fibre"
                        >
                            <Input />
                        </Form.Item>

                        </div>
                        <div style={{flex:1,minWidth:"400px"}}>
                            {user.type == "client" &&
                            <>
                                <Form.Item
                                    name={["comments", 'client_nutrition']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4}/>
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'nutrition']}
                                    label="Coach Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                            {user.type == "coach" &&
                            <>
                                <Form.Item
                                    name={["comments", 'nutrition']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'client_nutrition']}
                                    label="Client Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                        </div>
                    </div>

                    <Divider style={{marginTop:0}}>Lifestyle</Divider>
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div style={{flex:1,minWidth:"500px"}}>
                            <Form.Item
                                name={["compliance", 'sleep_hours']}
                                label="Average hours sleep"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance", 'sleep_quality']}
                                label="Average sleep quality"
                                labelCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 8 }
                                }}
                                wrapperCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 14, offset: 2 },
                                }}
                            >
                                <Slider
                                    included={true}
                                    marks={{
                                        5:"Amazing Sleep",
                                        4:" ",
                                        3:"Average",
                                        2:" ",
                                        1:{
                                            label: <div style={{'white-space':"nowrap"}}>Terrible Sleep</div>
                                        }
                                    }}
                                    min={1}
                                    max={5}
                                    step={1}
                                />
                            </Form.Item>
                            <Form.Item
                                name={["compliance", 'steps']}
                                label="Average Daily Steps"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={["compliance", 'water']}
                                label="Average Daily litres of water drunk"
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div style={{flex:1,minWidth:"400px"}}>
                            {user.type == "client" &&
                            <>
                                <Form.Item
                                    name={["comments", 'client_lifestyle']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4}/>
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'lifestyle']}
                                    label="Coach Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                            {user.type == "coach" &&
                            <>
                                <Form.Item
                                    name={["comments", 'lifestyle']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'client_lifestyle']}
                                    label="Client Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                        </div>
                    </div>

                    <Divider style={{marginTop:0}}>Other</Divider>
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div style={{flex:1,minWidth:"500px"}}>
                            <Form.Item
                                name={["compliance", 'energy']}
                                label="Energy"
                                labelCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 8 }
                                }}
                                wrapperCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 14, offset: 2 },
                                }}
                            >
                                <Slider
                                    included={true}
                                    marks={{
                                        5:"Lots of energy",
                                        4:" ",
                                        3:"Average",
                                        2:" ",
                                        1:{
                                            label: <div style={{'white-space':"nowrap"}}>No energy</div>
                                        }
                                    }}
                                    min={1}
                                    max={5}
                                    step={1}
                                />
                            </Form.Item>
                            <Form.Item
                                name={["compliance", 'hunger']}
                                label="Hunger"
                                labelCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 8 }
                                }}
                                wrapperCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 14, offset: 2 },
                                }}
                            >
                                <Slider
                                    included={true}
                                    marks={{
                                        1:"Not Hungry",
                                        2:" ",
                                        3:"Average",
                                        4:" ",
                                        5:{
                                            label: <div style={{'white-space':"nowrap"}}>Very Hungry</div>
                                        }
                                    }}
                                    min={1}
                                    max={5}
                                    step={1}
                                />
                            </Form.Item>
                            <Form.Item
                                name={["compliance", 'stress']}
                                label="Stress"
                                labelCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 8 }
                                }}
                                wrapperCol = {{
                                    xs: { span: 24 },
                                    sm: { span: 14, offset: 2 },
                                }}
                            >
                                <Slider
                                    included={true}
                                    marks={{
                                        1:"Not Stressed",
                                        2:" ",
                                        3:"Average",
                                        4:" ",
                                        5:{
                                            label: <div style={{'white-space':"nowrap"}}>Very Stressed</div>
                                        }
                                    }}
                                    min={1}
                                    max={5}
                                    step={1}
                                />
                            </Form.Item>


                        </div>
                        <div style={{flex:1,minWidth:"400px"}}>
                            {user.type == "client" &&
                            <>
                                <Form.Item
                                    name={["comments", 'client_other']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4}/>
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'other']}
                                    label="Coach Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                            {user.type == "coach" &&
                            <>
                                <Form.Item
                                    name={["comments", 'other']}
                                    label="Comments"
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                {props.id &&
                                <Form.Item
                                    name={["comments", 'client_other']}
                                    label="Client Comments"
                                >
                                    <Input.TextArea rows={4} disabled/>
                                </Form.Item>
                                }
                            </>
                            }
                        </div>
                    </div>

            </div>





			<div style={{display:"flex",justifyContent:"right", gap:10}}>
				<NavLink to={(user.type == "coach")?"/client/"+props.clientId+"/checkins":"/checkins"}>
					<Button>Cancel</Button>
				</NavLink>

				<Button type="primary" htmlType="submit" loading={isSaving}>{(isSaving)?'Saving':'Save'}</Button>
			</div>
        </Form>
	);
}