import React, { useState, useEffect,useContext  } from "react";
import { Table,Avatar,Button,Input } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API2 from "../../components/api2";
import SearchTable from "../../components/SearchTable";

export default function Users(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
        setBreadCrumbs([
            <span><i className="fas fa-users"/> Users</span>
        ]);
	}, []);

	const columns = [
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => {return (!a.name)?-1:a.name.localeCompare(b.name)},
        	align:'left',
            render: (text, record) => (
                <div>
                    <span><Avatar src={(record.image)?record.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/></span>
                    <span style={{paddingLeft:"20px"}}>{record.name}</span>
                </div>
            )
		},
		{
			title: 'Email',
			key: 'email',
            dataIndex: 'email',
            sorter: (a, b) => {return (!a.email)?-1:a.email.localeCompare(b.email)},
			align:'left'
		},
		{
			title: 'Mobile',
			key: 'mobile',
            dataIndex: 'mobile',
            sorter: (a, b) => a.age - b.age,
		},
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
            sorter: (a, b) => {return (!a.type)?-1:a.type.localeCompare(b.type)},
        },
		{
			title: 'Actions',
            key: 'actions',
            render: (text, record) => (
				<div>
					<span><NavLink to={"/user/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
				</div>
			),
		},
	];

	return (
		<div>
            <div style={{display:"flex",paddingBottom:"10px"}}>
                <NavLink to="/user/new" activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Create User </Button>
                </NavLink>
            </div>

            <SearchTable columns={columns} url="/users"/>
        </div>
	);
};