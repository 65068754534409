import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import { Line } from '@ant-design/charts';
import { Tabs , Spin, Divider, List, Form, Input,Switch, Button,Select, Table } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import RiskAssessment from '../../forms/riskAssessment'
import API2 from "../../components/api2";
import Moment from 'react-moment';
import SearchTable from "../../components/SearchTable";
import {notification} from "antd/lib/index";


const { TabPane } = Tabs;
const { Option } = Select;

var CanvasJSReact = require('../../assets/canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.default.CanvasJSChart;


export default function BodyComposition(props) {

	let id = props.id;
    const AP = API2();

	const [graphWeightData, setGraphWeightData] = useState([]);
	const [graphFatData, setGraphFatData] = useState([]);
	const [graphMassData, setGraphMassData] = useState([]);
	const [graphSkinData, setGraphSkinData] = useState([]);
	const [graphGirthData, setGraphGirthData] = useState([]);

    const [showWeightLine, setShowWeightLine] = useState(true);
    const [showFoldsLine, setShowFoldsLine] = useState(true);
    const [showGirthsLine, setShowGirthsLine] = useState(true);
    const [showFatLine, setShowFatLine] = useState(true);
    const [showLBMLine, setShowLBMLine] = useState(true);

	const bodyCompositionColumns = [
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'date',
			sorter: true,
			align:'center',
			onHeaderCell:function(column){
				//column.style = {minWidth:30};
				//debugger;
			},
			render: (text, record) => (
				<div>
					<Moment format="DD/MM/YYYY" unix>{record.date}</Moment>
				</div>
			)
		},
		{
			title: 'Average Weekly Weight',
			key: 'weight',
			dataIndex: 'weight',
			sorter: true,
			align:'center',
			width:210,
		},
		{
			title: 'Sum Of Skin Folds (mm)',
			key: 'body_density',
			dataIndex: 'body_density',
			sorter: true,
			align:'center',
			width:210,
			render: function(text, record){
				let total = 0;
				if(!record.skin_folds) return '-';
				for(const [key, value] of Object.entries(record.skin_folds)){
					total += parseFloat(value);
				}

				if(total==0) return '-';
				return total;
			}
		},
		{
			title: 'Sum Of Girths',
			key: 'body_density',
			dataIndex: 'body_density',
			sorter: true,
			align:'center',
			width:170,
			render: function(text, record){
				let total = 0;
				if(!record.measurements) return '-';
				for(const [key, value] of Object.entries(record.measurements)){
					total += parseFloat(value);
				}

				if(total==0) return '-';
				return total;
			}
		},
		{
			title: 'Body Fat %',
			key: 'body_fat',
			dataIndex: 'body_fat',
			sorter: true,
			align:'center',
			width:170,
			render: function(text, record){
				return (Math.round(text * 100) / 100)+"%";
			}
		},
		{
			title: 'Lean Body Mass',
			key: 'fat_free_weight',
			dataIndex: 'fat_free_weight',
			sorter: true,
			align:'center',
			width:170,
			render: function(text, record){
				return Math.round(text * 100) / 100;
			}
		},
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <span><NavLink to={"/client/"+id+"/body-composition/"+record.id}><Button type="primary" size="small" shape="round"><i className="far fa-edit"/></Button></NavLink></span>
                </div>
            ),
        },
    ];


    function getBCData(){

        //setLoading(true);

        AP.get("/client/"+id+"/body-compositions")
            .then(function(res){
                let gd = [];
                let gf = [];
                let gs = [];
                let gg = [];
                let gm = [];
                for(let p of res.data){
                    const date = new Date(p.date*1000);

                    gd.push({"x":date,y:p.weight});
                    let total = 0;
                    for (let [n, v] of Object.entries(p.skin_folds)) {
                        total += parseFloat(v);
                    }
                    gs.push({"x":date,y:total});
                    total = 0;
                    for (let [n, v] of Object.entries(p.measurements)) {
                        total += parseFloat(v);
                    }
                    if(total) {
                        gg.push({"x": date, y: total});
                    }
                    gf.push({"x":date,y:p.body_fat});
                    gm.push({"x":date,y:p.fat_free_weight});
                }
                setGraphWeightData(gd);
                setGraphSkinData(gs);
                setGraphGirthData(gg);
                setGraphFatData(gf);
                setGraphMassData(gm);
            });
    }

	useEffect(() => {
        getBCData();
	}, []);


    const graphOptions = {
        axisX:{
            valueFormatString: "DD MMM YYYY",
        },
        axisY: [],
        axisY2: [],
        backgroundColor: "transparent",
        data: []
    };
    if(showWeightLine){
        graphOptions.axisY.push({
            title: "Average Weekly Weight",
            lineColor: "#4f81bc",
            titleFontColor: "#4f81bc",
            labelFontColor: "#4f81bc"
        });
        graphOptions.data.push({
            type: "spline",
            showInLegend: true,
            axisYIndex: 0, //Defaults to Zero
            name: "Average Weight",
            dataPoints: graphWeightData,
            xValueFormatString: "DD MMM YYYY",
        });
    }
    if(showFatLine){
        graphOptions.axisY.push({
            title: "Body Fat %",
            lineColor: "#C24642",
            titleFontColor: "#C24642",
            labelFontColor: "#C24642"
        });
        graphOptions.data.push({
            type: "spline",
            showInLegend: true,
            axisYIndex: 1, //When axisYType is secondary, axisYIndex indexes to secondary Y axis & not to primary Y axis
            name: "Body Fat %",
            dataPoints: graphFatData,
            xValueFormatString: "DD MMM YYYY",
        });
    }
    if(showLBMLine){
        graphOptions.axisY2.push({
            title:"Lean Body Mass",
            lineColor: "#9bbb58",
            titleFontColor: "#9bbb58",
            labelFontColor: "#9bbb58"
        });
        graphOptions.data.push({
            type: "spline",
            showInLegend: true,
            axisYType: "secondary",
            axisYIndex: 0, //When axisYType is secondary, axisYIndex indexes to secondary Y axis & not to primary Y axis
            name: "Body Mass",
            dataPoints: graphMassData,
            xValueFormatString: "DD MMM YYYY"
        });
    }
    if(showFoldsLine){
        graphOptions.axisY2.push({
            title:"Sum Of Skin Folds",
            lineColor: "#23bfaa",
            titleFontColor: "#23bfaa",
            labelFontColor: "#23bfaa"
        });
        graphOptions.data.push({
            type: "spline",
            showInLegend: true,
            axisYType: "secondary",
            axisYIndex: 1, //When axisYType is secondary, axisYIndex indexes to secondary Y axis & not to primary Y axis
            name: "Sum Of Skin Folds",
            dataPoints: graphSkinData,
            xValueFormatString: "DD MMM YYYY",
        });
    }
    if(showGirthsLine){
        graphOptions.axisY2.push({
            title:"Sum Of Girths",
            lineColor: "#8064a1",
            titleFontColor: "#8064a1",
            labelFontColor: "#8064a1"
        });
        graphOptions.data.push({
            type: "spline",
            showInLegend: true,
            axisYType: "secondary",
            axisYIndex: 2, //When axisYType is secondary, axisYIndex indexes to secondary Y axis & not to primary Y axis
            name: "Sum Of Girths",
            dataPoints: graphGirthData,
            xValueFormatString: "DD MMM YYYY",
        });
    }

    function toggleLines($line){
        if($line == 'weight') setShowWeightLine(!showWeightLine);
        if($line == 'folds') setShowFoldsLine(!showFoldsLine);
        if($line == 'girth') setShowGirthsLine(!showGirthsLine);
        if($line == 'fat') setShowFatLine(!showFatLine);
        if($line == 'lmb') setShowLBMLine(!showLBMLine);
    }

    return (

        <>

            <div style={{display:"flex", flexWrap:"wrap", "margin-bottom":"60px"}}>
                <div style={{width:"100%"}}>
                    <div style={{paddingBottom:20, display:"flex",gap:10, justifyContent:"center"}}>
                        <Button type={(showWeightLine)?"primary":"default"} size="small" shape="round" onClick={()=>toggleLines('weight')}>Average Weekly Weight</Button>
                        <Button type={(showFoldsLine)?"primary":"default"} size="small" shape="round" onClick={()=>toggleLines('folds')}>Sum Of Skin Folds</Button>
                        <Button type={(showGirthsLine)?"primary":"default"} size="small" shape="round" onClick={()=>toggleLines('girth')}>Sum Of Githss</Button>
                        <Button type={(showFatLine)?"primary":"default"} size="small" shape="round" onClick={()=>toggleLines('fat')}>Body Fat %</Button>
                        <Button type={(showLBMLine)?"primary":"default"} size="small" shape="round" onClick={()=>toggleLines('lmb')}>Lean Body Mass</Button>
                    </div>
                    <CanvasJSChart options={graphOptions} />
                </div>
            </div>

            <div style={{display:"flex",paddingBottom:"10px"}}>
                <NavLink to={"/client/"+id+"/body-composition/new"} activeClassName="current" style={{marginLeft:"auto"}}>
                    <Button icon={<i className="far fa-user"></i>}>&nbsp;Add Body Composition </Button>
                </NavLink>
            </div>

            <SearchTable columns={bodyCompositionColumns} url={"/client/"+id+"/body-compositions"}/>
        </>
	);
}