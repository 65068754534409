import React, {} from "react";

import "./../css/termsAndConditions2.css"



export default function TermsAndConditionsText2(props) {


    return (
        <div className="zwoocommerce-terms-and-conditions" style={{padding:15}}>
			<div className="elementor-container elementor-column-gap-default importedTermsAndConditions">
				<div
					className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6022b4f"
					data-id="6022b4f" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">

						<p className="c81">
							<span className="c35" style={{color:"red"}}>2 Course Enrolment, Member Accreditation and Ongoing Registration </span>
							<span className="c0 c44">1 </span>
							<span className="c0">Metabolic Health Systems (MHS)</span>
							<span className="c0 c44">&nbsp;</span>
						</p>
						<p className="c76"><span className="c2">1. Definitions and Interpretation </span></p>
						<p className="c3 c110"><span className="c1">In these Terms and Conditions, a reference to the following words has the meaning set out adjacent to them, unless the context in which the words are used requires otherwise: </span></p>
						<p className="c30"><span className="c12">Agreement </span><span className="c1">means the Registration Form, your Application, any Direct Debit Request and Direct Debit Agreement that you have completed, these Terms and Conditions and any schedule, annexure or other attachment and any other practice, rules, or directions reasonably provided by the </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c90"><span className="c12">Confidential Information </span><span className="c1">includes any information which either party accesses or which is communicated to one party (Receiving Party) by the other (Disclosing Party) in the course of Your Accreditation, Registered Membership, and completion of the Course which: </span></p>
						<p className="c32"><span className="c1">(a) is identified as confidential; or </span></p>
						<p className="c18"><span className="c1">(b) which the Receiving Party should reasonably expect to be confidential. </span></p>
						<p className="c119"><span className="c1">&nbsp;</span></p>
						<p className="c78"><span className="c1">It includes, but is not limited to: </span></p>
						<p className="c58"><span className="c1">(a) any trade secrets or information relating to the </span><span className="c8">MHS</span><span className="c1">, other Registered Members, or clients; (b) client lists or requirements; </span></p>
						<p className="c71"><span className="c1">(c) suppliers; </span></p>
						<p className="c18"><span className="c1">(d) terms of trade; </span></p>
						<p className="c18"><span className="c1">(e) pricing lists; </span></p>
						<p className="c18"><span className="c1">(f) pricing structures; </span></p>
						<p className="c18"><span className="c1">(g) marketing information and plans; </span></p>
						<p className="c18"><span className="c1">(h) Intellectual Property; </span></p>
						<p className="c18"><span className="c1">(i) inventions; </span></p>
						<p className="c18"><span className="c1">(j) business plans or dealings; </span></p>
						<p className="c18"><span className="c1">(k) technical data; </span></p>
						<p className="c18"><span className="c1">(l) employees or officers; </span></p>
						<p className="c18"><span className="c1">(m) financial information and plans; </span></p>
						<p className="c18"><span className="c1">(n) designs; </span></p>
						<p className="c18"><span className="c1">(o) research activities; and </span></p>
						<p className="c18"><span className="c1">(p) software, and the source code of any such software, of the </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c18 c33"><span className="c1"></span></p>
						<p className="c75"><span className="c1">It does not include information which: </span></p>
						<p className="c18"><span className="c1">(q) is generally available in the public domain; and/or </span></p>
						<p className="c18"><span className="c1">(r) is required to be disclosed by law by the Receiving Party. </span></p>
						<p className="c33 c78"><span className="c1"></span></p>
						<p className="c114"><span className="c12">Direct Debit Request </span><span className="c1">means an application to request that </span><span className="c8">MHS</span><span className="c1">&nbsp;directly debit payments from your nominated banking account, which will take the form prescribed by </span><span className="c8">MHS</span><span className="c1">&nbsp;from time to time. </span></p>
						<p className="c109"><span className="c12">Direct Debit Agreement </span><span className="c1">means the agreement provided to you by </span><span className="c8">MHS</span><span className="c1">&nbsp;enabling it to directly debit payments from your nominated banking account, which will take the form prescribed by </span><span className="c8">MHS</span><span className="c1">&nbsp;from time to time. </span></p>
						<p className="c10"><span className="c12">Dishonour Fee </span><span className="c1">means the Dishonour Fee referred to in the Membership Schedule. </span></p>
						<p className="c43"><span className="c12">Facilities </span><span className="c1">includes but is not limited to tools, equipment, machinery, weights, or other amenities of one or more members of the </span><span className="c8">MHS</span><span className="c1">&nbsp;at the Location. </span></p>
						<p className="c95"><span className="c12">Intellectual Property </span><span className="c1">means any and all intellectual and industrial property rights throughout the world, whether subsisting now or in the future and includes all copyright and analogous rights, all rights in relation to inventions (including patent rights), registered and unregistered trademarks, designs (whether or not registered or registrable), circuit layouts, trade names, trade secrets, business names, company names or internet domain names and copyright. </span></p>
						<p className="c10"><span className="c12">Location </span><span className="c1">means any establishment or venue where the Services are carried out. </span></p>
						<p className="c33 c54"><span className="c1"></span></p>
						<p className="c65"><span className="c46">MHS</span><span className="c12">&nbsp;</span><span className="c1">means </span><span className="c8">Metabolic Health Systems Pty LTD </span></p>
						<p className="c107"><span className="c12">You/Your </span><span className="c1">means the </span><span className="c8">Subscriber</span><span className="c1">. </span></p>
						<p className="c18"><span className="c2">1.1 Interpretation </span></p>
						<p className="c123"><span className="c1">(a) Words denoting the singular number include the plural, and vice versa. </span></p>
						<p className="c18"><span className="c1">(b) Words denoting any gender include all genders. </span></p>
						<p className="c15"><span className="c1">(c) All references to &ldquo;$&rdquo; and &ldquo;dollars&rdquo; and &ldquo;USD&rdquo; are to the lawful currency of the United States of America. Unless otherwise states all monetary figures are in the lawful currency of the United States of America. </span></p>
						<p className="c32"><span className="c1">(d) Headings are for convenience only and do not affect interpretation.</span></p>
						<p className="c49"><span className="c1">(e) References to clauses and sub-clauses are references to clauses and sub-clauses of these Terms &amp; Conditions. </span></p>
						<p className="c84"><span className="c1">(f) Where any of the Parties hereto comprises more than one person, any covenants, agreement, or obligation undertaken, or benefits received by virtue of this Deed, shall be undertaken, or received by all of them jointly and each of them severally. </span></p>
						<p className="c68"><span className="c1">(g) Reference to any legislation or any section or provision thereof includes any statutory modification or re-enactment, or any statutory provision substituted including any ordinances, by-laws, regulations, or other statutory instruments issued under such legislation. </span></p>
						<p className="c80 c93"><span className="c1">(h) References to </span><span className="c8">MHS</span><span className="c1">&nbsp;include references to each company forming the </span><span className="c8">MHS</span><span className="c1">&nbsp;as the context and circumstances require. </span></p>
						<p className="c32"><span className="c1">(i) Other forms of defined words have corresponding meanings. </span></p>
						<p className="c92"><span className="c2">2. Acceptance and Commencement </span></p>
						<p className="c48"><span className="c1">(a) This Agreement commences on the date that</span><span className="c8">&nbsp;you subscribe to the nutritional and metabolic health online subscription. </span><span className="c1">&nbsp;</span></p>
						<p className="c27"><span className="c2">3. Scope of This Agreement </span></p>
						<p className="c116"><span className="c1">This Agreement does not create any fiduciary, employment, agency, or partnership relationship between You and any or all of the members of the </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c27"><span className="c2">4. Variation </span></p>
						<p className="c106"><span className="c1">(a) </span><span className="c8">MHS</span><span className="c1">&nbsp;may from time to time unilaterally make modifications, variations or amendments (</span><span className="c12">Variations</span><span className="c1">) to this Agreement. </span></p>
						<p className="c61"><span className="c1">(b) Such Variations may be necessary where, for example, our complimentary service providers (such as insurance providers) make variations to their own terms or agreements to which we are a party. </span></p>
						<p className="c102"><span className="c1">(c) Where it is feasible for us to do so, we will provide you with thirty (30) days&rsquo; notice of the proposed Variation to these terms. If you do not agree to the proposed Variation, notwithstanding any other provision of this Agreement, you will be able to terminate this Agreement by providing us written notice, with such termination taking effect from the date that the Variation is to become effective, without penalty. </span></p>
						<p className="c6"><span className="c1">(d) If we do not receive a notice from you objecting to a Variation pursuant to 4(c), and you continue to request our Services</span><span className="c8">&nbsp;</span><span className="c1">or </span><span className="c8">operate</span><span className="c1">&nbsp;as a Member of </span><span className="c8">MHS</span><span className="c1">, you will be deemed to have accepted the Variation. </span></p>
						<p className="c33 c40"><span className="c1"></span></p>
						<p className="c104"><span className="c9">5</span><span className="c2">. Eligibility </span></p>
						<p className="c3"><span className="c1">(a) You must be over the age of eighteen (18) to participate in </span><span className="c8">the use of the </span><span className="c1">&nbsp;</span><span className="c8">MHS</span><span className="c1">&nbsp;software unless written permission from a parent or guardian is supplied. &nbsp;</span></p>
						<p className="c80"><span className="c1">(</span><span className="c8">b</span><span className="c1">) </span><span className="c8">MHS</span><span className="c1">&nbsp;may at their absolute discretion request additional information, supporting documentation and professional references, in addition to the information presented on the Registration Form or any Application, in order to further assess Your eligibility to participate </span><span className="c8">in the use of this software</span><span className="c1">. </span></p>
						<p className="c33 c56"><span className="c1"></span></p>
						<p className="c32"><span className="c9">6.</span><span className="c2">&nbsp;</span><span className="c2">Payments Generally </span></p>
						<p className="c18"><span className="c1">(a) </span><span className="c1">&nbsp;the service Fees that you will be charged will be calculated on the following basis:</span></p>
						<ol className="c37 lst-kix_mna6hra14mcc-1 start" start="1">
							<li className="c86 li-bullet-0"><span className="c1">an upfront annual fee of $179.00; or</span></li>
							<li className="c86 li-bullet-0"><span className="c1">a monthly fee of $19.00</span></li>
						</ol>
						<ol className="c37 lst-kix_mna6hra14mcc-0 start" start="1">
							<li className="c42 c125 li-bullet-0"><span className="c8">Y</span><span className="c1">ou acknowledge and agree that: </span></li>
						</ol>
						<p className="c34"><span className="c1">(i) a surcharge of 2% will apply to all credit card payments that are processed by </span><span className="c8">MHS</span><span className="c1">; and </span></p>
						<p className="c122"><span className="c1">(ii) Direct debits are subject to the Direct Debit Request and Direct Debit Agreement that you are provided by </span><span className="c8">MHS</span><span className="c1">&nbsp;at the time of submitting and Application or Registration Form, or if a third-party direct debit provider is used, the third party&rsquo;s direct debit terms and conditions. </span></p>
						<p className="c14"><span className="c1">(b) If any payment of Fees that you are required to make to </span><span className="c8">MHS</span><span className="c1">&nbsp;is </span><span className="c1">dishonoured</span><span className="c1">&nbsp;then you will be liable to pay the following: </span></p>
						<p className="c28"><span className="c1 c42">(i) a Dishonour Fee of $</span><span className="c8 c42">6</span><span className="c1 c42">.00 usd</span></p>
						<p className="c113"><span className="c1">(c) You acknowledge that the amounts referred to in 8.5(b)(ii) are a genuine estimate of the administrative fees incurred by </span><span className="c8">MHS</span><span className="c1">&nbsp;in addressing a </span><span className="c1">dishonoured</span><span className="c1">&nbsp;payment and subsequently reinstating You as a Registered Member. </span></p>
						<p className="c120"><span className="c1">(d) Your first Payment for a Course will be payable on the date that approval of Your Application is granted for the Course, and the recurring Payments will be due on the same date of the following month. </span></p>
						<p className="c32"><span className="c1">(e) Any fee deferral or extension fees are payable on the same date that Your deferral or extension is agreed. </span></p>
						<p className="c97"><span className="c1">(f) It is Your responsibility to ensure that on each payment due date there are sufficient cleared funds in Your nominated account to meet Your scheduled payment. Should Your credit or debit card payment decline on the agreed date, </span><span className="c8">MHS</span><span className="c1">&nbsp;reserves its right to continue attempts to process Your payment. </span></p>
						<p className="c62"><span className="c1">(g) If a payment is declined at the time of attempting to process the payment, </span><span className="c8">MHS</span><span className="c1">&nbsp;will attempt to process the declined amount once every twenty-four (24) hours for seventy-two (72) hours (three (3) times in three (3) days). </span></p>
						<p className="c4"><span className="c1">(h) If a payment remains outstanding and in arrears after an attempt to process that payment in the manner described at (g), a member of the </span><span className="c8">MHS</span><span className="c1">&nbsp;(at its discretion) will charge You the Dishonour Fee and You agree to pay the Dishonour Fee in addition to the payment. </span></p>
						<p className="c72"><span className="c1">(i) If You fail to pay the outstanding payment and the Dishonour Fee and any interest payable within thirty (30) days of the original declined payment, </span><span className="c8">MHS</span><span className="c1">&nbsp;will cancel your Registered Membership and access to any online platform, resource or communication channel hosted by any of the </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c67"><span className="c1">(j) If Your Registered Membership is </span><span className="c8">canceled</span><span className="c1">&nbsp;it will need to be reinstated. If You request that Your Registered Membership is reinstated, You must pay all outstanding payments, Dishonour Fees, interest and the Reinstatement Fee prior to your Registered Membership being reinstated. </span></p>
						<p className="c41"><span className="c1">(k) If a payment is declined after your Registered Membership has been reinstated, the process and consequences referred to in this clause 7.5 are repeated. If Your Registered Membership is </span><span className="c8">canceled</span><span className="c1">&nbsp;for a second time, Your Registered Membership may not be reinstated, and reinstatement is at the sole discretion of </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c19"><span className="c1">(l) Should Your credit or debit card expire or be </span><span className="c8">canceled</span><span className="c1">, it is Your responsibility to provide </span><span className="c8">MHS</span><span className="c1">&nbsp;with new card details to ensure payments continue to be successfully debited. </span></p>
						<p className="c70"><span className="c1">(m) </span><span className="c8">MHS</span><span className="c1">&nbsp;may charge interest at the rate of 12.5% per annum on any payment that remains unpaid fourteen (14) days after the payment was due. </span><span className="c8">MHS</span><span className="c1">&nbsp;reserves the right to engage external debt collection services for the collection of unpaid debts, and the right to commence legal proceedings for any outstanding amounts owed to any member of </span><span className="c8">MHS</span><span className="c1">. You will be liable for all costs involved, including legal fees, on a solicitor/client basis. </span></p>
						<p className="c24"><span className="c1">(n) </span><span className="c8">MHS</span><span className="c1">&nbsp;reserves the right to engage external debt collection services for the collection of unpaid debts, and the right to commence legal proceedings for any outstanding amounts owed to any member of </span><span className="c8">MHS</span><span className="c1">. You acknowledge that you will be liable for all costs and expenses involved in our recovery of unpaid debts, including legal fees; on a solicitor/client basis, as well as the costs that we incur in appointing an agent or debt collection firm to recover the unpaid debt. We reserve at our discretion the right to provide any information regarding any unpaid debt or credit position to relevant credit reporting agencies. </span></p>
						<p className="c117"><span className="c1">(o) </span><span className="c8">MHS</span><span className="c1">&rsquo;s pricing structure, payment methods, payment processes and these Terms and Conditions may be amended at any given time at the </span><span className="c8">MHS</span><span className="c1">&rsquo;s</span><span className="c1">&nbsp;reasonable discretion. </span></p>
						<p className="c59"><span className="c1">(p) All changes will apply from the date that the amended or new Terms and Conditions are posted on the website of a member of the </span><span className="c8">MHS</span><span className="c1">&nbsp;or are provided to You, whichever is earlier. </span></p>
						<p className="c11"><span className="c2">10. Duration and Renewal of </span><span className="c9">Subscription.</span></p>
						<p className="c45"><span className="c1">(a) Subject to compliance with these Terms and Conditions, </span><span className="c8">the Subscription</span><span className="c1">&nbsp;continues until </span><span className="c8">further notice </span><span className="c1">or as set out in the </span><span className="c8">Subscription</span><span className="c1">&nbsp;Schedule. </span></p>
						<p className="c51"><span className="c1">(b) Your </span><span className="c8">Subscription </span><span className="c1">&nbsp;will be automatically renewed each calendar year, subject to: (i) Your continued compliance with the terms of this Agreement; </span></p>
						<p className="c79"><span className="c1">(ii) Your satisfaction and completion of all continuing professional development and educational modules prescribed by </span><span className="c8">MHS</span><span className="c1">&nbsp;within the relevant period; and </span></p>
						<p className="c28"><span className="c1">(iii) Your payment of all Membership Fees. </span></p>
						<p className="c85"><span className="c1">(c) You shall inform the Association via email or telephone thirty (30) days prior to the end of each calendar year, if You do not intend to renew Your Accreditation or Registered Membership for the following calendar year. </span></p>
						<p className="c11"><span className="c9">7</span><span className="c2">. Suspension Or Termination of </span><span className="c9">Subscription</span><span className="c2">&nbsp;</span></p>
						<p className="c48"><span className="c46">7</span><span className="c12">.1 Termination Generally </span></p>
						<p className="c96"><span className="c1">(a) You may terminate Your </span><span className="c8">Subscription</span><span className="c1">&nbsp;at any time by giving the Association at least thirty (30) days written notice. The notice must be sent to the email address nominated by </span><span className="c8">MHS</span><span className="c1">&nbsp;from time to time. </span></p>
						<p className="c33 c96"><span className="c1"></span></p>
						<p className="c108"><span className="c1">(b) In addition to the specific right afforded at 8.5(i), the </span><span className="c8">MHS</span><span className="c1">&nbsp;may suspend or terminate Your </span><span className="c8">Subscription</span><span className="c1">&nbsp;if: </span></p>
						<p className="c20"><span className="c1">(i) You breach any of the terms set out in this Agreement; </span></p>
						<p className="c40"><span className="c1">(ii) You require </span><span className="c8">MHS</span><span className="c1">&nbsp;to act unlawfully or unethically; </span></p>
						<p className="c53"><span className="c1">(iii) the </span><span className="c8">MHS</span><span className="c1">&nbsp;reasonably believes that Registered Membership is no longer appropriate; or (iv) You fail to make Payment. </span></p>
						<p className="c105"><span className="c1">(c) If Your </span><span className="c8">Subscription</span><span className="c1">&nbsp;is terminated by either You or by the </span><span className="c8">MHS</span><span className="c1">&nbsp;pursuant to 8.5(i) or 10.1(a), You agree that the </span><span className="c8">MHS</span><span className="c1">&nbsp;is entitled to all payments that would have been due for the remainder of the current membership term at the time of termination (if any). </span></p>
						<p className="c83"><span className="c1">(d) You acknowledge and agree that the fees payable pursuant to clause 11.1(c) account for the genuine loss that will be suffered by </span><span className="c8">MHS</span><span className="c1">&nbsp;as a result of the related termination and do not constitute, and must not be construed as, a penalty. </span></p>
						<p className="c101"><span className="c1">(e) On termination of this Agreement, You agree to promptly return (where possible) or delete or destroy (where not possible to return) all of the members of the </span><span className="c8">MHS</span><span className="c1">s&rsquo; Confidential Information and Intellectual Property, and all documents containing or relating to all of the members of the </span><span className="c8">MHS</span><span className="c1">s&rsquo; Confidential Information and Intellectual Property. </span></p>
						<p className="c88"><span className="c1">(f) Any accrued rights or responsibility of You or the </span><span className="c8">MHS</span><span className="c1">&nbsp;are not affected by the termination of this Agreement. </span></p>
						<p className="c50"><span className="c1">(g) On termination of this Agreement, the members of the </span><span className="c8">MHS</span><span className="c1">&nbsp;will remove Your access to all Registered Membership Services including but not limited to online platforms and communication channels, face to face workshops and events and the members of the </span><span className="c8">MHS</span><span className="c1">&nbsp;will not be liable for any claims related to the removal of Your access to these, Your Registered Membership or Accredited status. </span></p>
						<p className="c69"><span className="c1">(h) No refund is provided if this Agreement, Your Accreditation or Registered Membership is terminated or suspended for any reason. </span></p>
						<p className="c33 c111"><span className="c1"></span></p>
						<p className="c121"><span className="c9">8</span><span className="c2">. Facilities </span></p>
						<p className="c17"><span className="c1">Any use of the </span><span className="c8">MHS</span><span className="c1">s&rsquo; property, Facilities or Locations are at Your own risk, and you indemnify them against any and all claims, damages, costs, or liabilities that may arise as a consequence of Your use of the property, Facilities or Location. </span></p>
						<p className="c17 c33"><span className="c1"></span></p>
						<p className="c49"><span className="c9">9</span><span className="c2">. Privacy </span></p>
						<p className="c31"><span className="c1">(a) It is a requirement of the </span><span className="c8 c63">Privacy Act 1988 </span><span className="c1">that You are informed about the collection of Your personal information and how the Association may use it. </span></p>
						<p className="c39"><span className="c1">(b) All personal information that is collected by </span><span className="c8">MHS</span><span className="c1">&nbsp;(including any of the individual entities comprising that group) will be collected, stored and used in accordance with the </span><span className="c8">MHS</span><span className="c1">&nbsp;Privacy Policy available on the website of each </span><span className="c8">MHS</span><span className="c1">&nbsp;company. </span></p>
						<p className="c87"><span className="c1">(c) The personal information You provide during the term of this Agreement is being collected for the purpose of processing Your Registration and Accreditation. You have a right to access and make alterations to Your personal information. </span></p>
						<p className="c66"><span className="c1">(d) The Association agrees not to disclose Your personal information to any third party unless or except: (i) as required for the legitimate interests of the </span><span className="c8">MHS</span><span className="c1">&nbsp;business; </span></p>
						<p className="c64"><span className="c1">(ii) to the extent that the disclosure relates only to the fact that you have previously participated in programs offered by Sports Nutrition Association, that your Accreditation is either current or non-current, that you are no longer a Member of the Association, or that you are no longer affiliated with or endorsed by the </span><span className="c8">MHS</span><span className="c1">; </span></p>
						<p className="c28"><span className="c1">(iii) legally required to do so or to its professional advisors. </span></p>
						<p className="c92"><span className="c9">10</span><span className="c2">. Publicity </span></p>
						<p className="c55"><span className="c1">(a) You must not make any public comment or remark that is, or is likely to be, disparaging, derogatory or harmful to </span><span className="c8">MHS</span><span className="c1">&nbsp;regarding: </span></p>
						<p className="c20"><span className="c1">(i) the nature and quality of the education services provided by </span><span className="c8">MHS</span><span className="c1">; </span></p>
						<p className="c40"><span className="c1">(ii) the membership and association services provided by the Association; and </span></p>
						<p className="c40"><span className="c1">(iii) any of the directors, agents, employees or other personnel of the </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c16"><span className="c1">(b) Where you have a complaint or criticism you must notify </span><span className="c8">MHS</span><span className="c1">&nbsp;of the complaint or criticism in writing (</span><span className="c12">Complaint Notice</span><span className="c1">). </span></p>
						<p className="c38"><span className="c1">(c) Upon receipt of a Complaint Notice </span><span className="c8">MHS</span><span className="c1">&nbsp;will contact You within ten (10) business days to resolve the matters the subject of the Complaint Notice. </span></p>
						<p className="c83"><span className="c1">(d) For the avoidance of doubt this clause 13 applies only in respect of public comments or remarks as contemplated by clause 13(a). Any disputes regarding the terms of this Agreement or the performance of the obligations of the parties under this Agreement must be dealt with in accordance with clause 19. </span></p>
						<p className="c11"><span className="c9">11</span><span className="c2">. Confidential Information </span></p>
						<p className="c118"><span className="c1">You agree not to disclose the Confidential Information of the </span><span className="c8">MHS</span><span className="c1">&nbsp;to any third party, to use all reasonable </span><span className="c8">endeavors</span><span className="c1">&nbsp;to the Confidential Information of the </span><span className="c8">MHS</span><span className="c1">&nbsp;from any </span><span className="c1">unauthorised</span><span className="c1">&nbsp;disclosure, and only to use the Confidential Information for the purpose for which it was disclosed or provided by </span><span className="c8">MHS</span><span className="c1">&nbsp;to You, and not for any other purpose. </span></p>
						<p className="c11"><span className="c2">1</span><span className="c9">2</span><span className="c2">. Intellectual Property </span></p>
						<p className="c26"><span className="c1">(a) The work and materials that </span><span className="c8">MHS</span><span className="c1">&nbsp;provides to you in carrying out the Services contains material which is owned by or licensed to </span><span className="c8">MHS</span><span className="c1">&nbsp;, or owned by third parties, and is protected by Australian and international laws (</span><span className="c12">Materials</span><span className="c1">). </span><span className="c8">MHS</span><span className="c1">&nbsp;owns the copyright in all creative, literary and original works incorporated in the Materials that </span><span className="c8">MHS</span><span className="c1">&nbsp;has created, including any sports nutrition programs, meal plans, exercise regimes, supplement programs, business plans, social media strategies and any other documents or concepts provided in either spoken or written form within the scope of Your </span><span className="c8">Subscription</span><span className="c1">&nbsp;with </span><span className="c8">MHS</span><span className="c1">, including those disseminated during professional development seminars. </span></p>
						<p className="c94"><span className="c1">(b) You agree that </span><span className="c8">MHS</span><span className="c1">&nbsp;owns all intellectual property rights in its Materials, and that nothing in these Terms and Conditions constitutes a transfer of any intellectual property ownership rights in </span><span className="c8">MHS</span><span className="c1">&nbsp;Materials, except as stated in these Terms and Conditions or with </span><span className="c8">MHS</span><span className="c1">&rsquo;s written permission. Your use of </span><span className="c8">MHS</span><span className="c1">&rsquo;s Materials does not grant You a license, or act as a right of use, of any of the intellectual property in the Materials, whether registered or unregistered, except as stated in these Terms and Conditions or with the Association&rsquo;s written permission. </span></p>
						<p className="c47"><span className="c1">(c) If You are provided with any Materials during Your Accreditation or Registered Membership, then the copyright in these Materials shall remain vested with </span><span className="c8">MHS</span><span className="c1">&nbsp;and shall only be used at </span><span className="c8">MHS</span><span className="c1">&rsquo;s discretion. You must not: </span></p>
						<p className="c28"><span className="c1">(i) alter or modify any of the Materials; </span></p>
						<p className="c40"><span className="c1">(ii) create derivative works from the Materials; or </span></p>
						<p className="c98"><span className="c1">(iii) use </span><span className="c8">MHS</span><span className="c1">&rsquo;s Materials for commercial purposes such as on sale to third parties, without </span><span className="c8">MHS</span><span className="c1">&rsquo;s prior express written permission. </span></p>
						<p className="c23"><span className="c1">(d) You acknowledge and agree that You must not use the </span><span className="c8">MHS</span><span className="c1">&rsquo;s Materials to create a business that is similar to that of the </span><span className="c8">MHS</span><span className="c1">, If You do so, </span><span className="c8">MHS</span><span className="c1">&nbsp;reserves the right to immediately terminate Your Registered Membership and the Agreement. </span></p>
						<p className="c80 c100"><span className="c1">(e) </span><span className="c8">MHS</span><span className="c1">&nbsp;reserves its right to pursue any loss or damage suffered as a result of copyright or Intellectual Property infringement by You or any third party. </span></p>
						<p className="c22"><span className="c1">(f) This clause survives termination of the Agreement. </span></p>
						<p className="c33 c99"><span className="c1"></span></p>
						<p className="c11"><span className="c2">1</span><span className="c9">3</span><span className="c2">. Indemnity </span></p>
						<p className="c60"><span className="c1">(a) You are liable for and agree to indemnify, defend, and hold the </span><span className="c8">MHS</span><span className="c1">&nbsp;harmless for and against any and all claims, liabilities, suits, actions, and expenses, including costs of litigation and legal costs, resulting directly or indirectly from: </span></p>
						<p className="c28"><span className="c1">(i) any information that is not accurate, up to date or complete or is misleading or a misrepresentation; </span></p>
						<p className="c52"><span className="c1">(ii) the </span><span className="c8">MHS</span><span className="c1">&nbsp;(or an individual member) providing You with </span><span className="c8">Subscription</span><span className="c1">; </span></p>
						<p className="c28"><span className="c1">(iii) any breach of this Agreement; and </span></p>
						<p className="c40"><span className="c1">(iv) you delivering Services to the public. </span></p>
						<p className="c40 c33"><span className="c1"></span></p>
						<p className="c93 c103"><span className="c1">(b) You agree to cooperate with the </span><span className="c8">MHS</span><span className="c1">&nbsp;(at Your own expense) in the handling of disputes, complaints, investigations, or litigation that arise as a result of Your Services including but not limited to disputes, complaints, investigations, or litigation that arises out of or relates to incorrect information You have given the </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c22"><span className="c1">(c) The obligations under this clause will survive termination of this Agreement. </span></p>
						<p className="c92"><span className="c2">1</span><span className="c9">4</span><span className="c2">. Limitation of Liability </span></p>
						<p className="c5"><span className="c1">(a) To the fullest extent permitted by law, the </span><span className="c8">MHS</span><span className="c1">&nbsp;shall not be liable to You for any costs, expenses, loss of or damage to goodwill, loss of sales or business, loss, or damage (whether direct, indirect, or consequential, and whether economic or other) arising from Your </span><span className="c8">Subscription</span><span className="c1">, or any termination of the rights granted to You under, or otherwise in connection with, this Agreement. </span></p>
						<p className="c57"><span className="c1">(b) The </span><span className="c8">MHS</span><span className="c1">&rsquo;s</span><span className="c1">&nbsp;liability to You, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising in connection with this Agreement, shall at all times be limited to the amount of the Payments made by you under this Agreement. </span></p>
						<p className="c11"><span className="c9">15</span><span className="c2">. Dispute Resolution (Mediation) </span></p>
						<p className="c7"><span className="c1">(a) If a dispute (</span><span className="c12">Dispute</span><span className="c1">) arises between the parties to this Agreement which they cannot resolve, then the party claiming that a Dispute has arisen must deliver to the other parties a notice containing particulars of the Dispute (</span><span className="c12">Dispute Notice</span><span className="c1">). </span></p>
						<p className="c115"><span className="c1">(b) During the period of 10 Business Days after delivery of the Dispute Notice, or any longer period agreed in writing by the parties to the Dispute (</span><span className="c12">Initial Period</span><span className="c1">), the parties to the Dispute (</span><span className="c12">Participants</span><span className="c1">) must meet and use their reasonable endeavours and act in good faith in an attempt to resolve the Dispute. </span></p>
						<p className="c82"><span className="c1">(c) If the Participants cannot resolve the Dispute then unless they all agree otherwise, the Participants must appoint a mediator to mediate the Dispute in accordance with the rules of the Resolution Institute. All Participants must participate in the mediation in good faith. </span></p>
						<p className="c21"><span className="c1">(d) The mediator must be agreed on by the Participants within 10 Business Days after the Dispute Notice is given to the Participants and if they cannot agree within that time the mediator will be nominated by the president of the Resolution Institute. </span></p>
						<p className="c32"><span className="c1">(e) The mediation concludes when: </span></p>
						<p className="c40"><span className="c1">(i) all the Participants agree in writing on a resolution of the Dispute; or </span></p>
						<p className="c126"><span className="c1">(ii) a Participant, not earlier than 20 Business Days after appointment of the mediator, has given 5 Business Days&rsquo; notice to the other Participants and to the mediator, terminating the mediation, and that 5 Business Days has expired without all the Participants agreeing in writing on a resolution of the issue. </span></p>
						<p className="c11"><span className="c9">16</span><span className="c2">. Goods and Services Tax </span></p>
						<p className="c13"><span className="c1">(a) Unless otherwise expressly stated, all amounts stated to be payable under this document are exclusive of goods and services tax (</span><span className="c12">GST</span><span className="c1">). If GST is imposed on any supply made under or in accordance with this document, then the GST payable must be paid to the supplier as an additional amount by the recipient of the supply, provided the supplier provides a tax invoice in respect of the taxable supply. </span></p>
						<p className="c128"><span className="c1">(b) If a party is entitled to be reimbursed or receive compensation for any of its costs, expenses or liabilities then the amount to be paid is to be reduced by the input tax credits to which that party is entitled to receive in relation to those amounts. </span></p>
						<p className="c11"><span className="c9">17</span><span className="c2">. General Matters</span></p>
						<p className="c3 c127"><span className="c1">(a) You must not assign, novate or otherwise transfer any of your rights or obligations under this document without the prior written consent of </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c36"><span className="c1">(b) Neither party is liable for any breach of its obligations under this document to the extent that the breach resulted from any event that is outside the reasonable control of the affected party and could not have been prevented by that party taking reasonable steps or overcome by the exercise of reasonable diligence and at a reasonable cost (including lack of supply, industrial action, fire, riot, war, embargo, civil commotion for act of God) provided that the affected party: </span></p>
						<p className="c74"><span className="c1">(i) promptly notifies the other party of the event (with appropriate details); and </span></p>
						<p className="c40"><span className="c1">(ii) takes all reasonable steps to work around or reduce the effects of the event. </span></p>
						<p className="c18"><span className="c1">(c) Any amendments to this Agreement must be made in writing and signed by the parties. </span></p>
						<p className="c89"><span className="c1">(d) You agree that the </span><span className="c8">MHS</span><span className="c1">, may use email to communicate with You and may use the email address that You provide for that purpose. </span></p>
						<p className="c77"><span className="c1">(e) You agree to release the </span><span className="c8">MHS</span><span className="c1">&nbsp;from any liability for any loss which You might incur if an email is intercepted or corrupted during transmission, or if a document which any of the members of the </span><span className="c8">MHS</span><span className="c1">&nbsp;prepared for You is altered by You or any other party without the written consent of the </span><span className="c8">MHS</span><span className="c1">. </span></p>
						<p className="c80 c124"><span className="c1">(f) This Agreement is governed by the laws of Queensland, Australia and You agree to submit to the non-exclusive jurisdiction of the courts of Queensland, Australia. </span></p>
						<p className="c25"><span className="c1">(g) Unless this document expressly states otherwise, a party may in its absolute discretion, give conditionally or unconditionally or withhold, any consent under this document. To be effective any consent under this document must be in writing. </span></p>
						<p className="c29"><span className="c1">(h) Any provision of this Agreement that is illegal, void, or unenforceable will be severed without prejudice to the balance of the provisions. </span></p>
					
					</div>
				</div>
			</div>
        </div>
	);
}