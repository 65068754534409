import React, {Component, useContext, useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import API from "../../components/api";
import { Tabs , Spin, Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import RiskAssessment from '../../forms/riskAssessment'
import API2 from "../../components/api2";

const { TabPane } = Tabs;



export default function Coach(props) {

	let id = props.match.params.id;
    const [coach, setCoach] = useState(false);
    const [overview, setOverview] = useState(false);
	const [form] = Form.useForm();
    const AP = API2();
	const setBreadCrumbs = useContext(BreadCrumbContext);

	useEffect(() => {

        AP.get('/coach/'+id)
            .then(function(res){
                setCoach(res);
                form.setFieldsValue(res);
            });

        AP.get('/coach/'+id+'/overview')
            .then(function(res){
                setOverview(res);
            });

    }, []);

	useEffect(() => {
		setBreadCrumbs([
			<NavLink to="/coaches"><i className="fas fa-users"></i> Coaches </NavLink>,
			,(coach)?<span><UserOutlined/> {coach.name}</span>:<Spin size="small" />
		]);
	}, [coach]);

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		},
	};

	let defaultTab = "1";
	if(props.match.params.tab == 'riskAssessment'){
		defaultTab = "2";
	}

	return (
        <div style={{maxWidth:"1328px",margin:"auto", paddingTop:"60px"}}>
			<Tabs defaultActiveKey={defaultTab} centered>
				<TabPane tab="OVERVIEW" key="1">
					<div style={{display:"flex",flexWrap:"wrap"}}>
						<div style={{flex:"1",minWidth:"300px"}}>

							<Form
								{...formItemLayout}
								form={form}
								style={{ margin:"auto"}}
							>
								<Form.Item
									name="name"
									label="Name"
								>
									<Input className="readOnly" readOnly="readOnly"/>
								</Form.Item>

								<Form.Item
									name="email"
									label="E-mail"
								>
									<Input className="readOnly" readOnly="readOnly"/>
								</Form.Item>

							</Form>
						</div>
						<div style={{flex:"1",minWidth:"300px",fontSize:"20px", textAlign:"center",alignSelf:"center",paddingBottom:"40px"}}>
							<div>
								{overview && overview.risk && <div>RISK: <span style={{color:(overview.risk=="high")?"red":"orange",textAlign:"center"}}>{overview.risk.toUpperCase()}</span></div>}
							</div>
						</div>
					</div>
				</TabPane>
				<TabPane tab="RISK ASSESSMENT" key="2">
					<RiskAssessment coach={coach} steps={true}></RiskAssessment>
				</TabPane>
			</Tabs>
		</div>
	);
}