import React, { useState, useEffect,useContext  } from "react";
import { Table, Button,Avatar } from 'antd';
import {NavLink} from "react-router-dom";
import BreadCrumbContext from "../../contexts/breadCrump";
import ClientsTable from "../../table/Clients";

export default function Clients(props) {

	const setBreadCrumbs = useContext(BreadCrumbContext);
	useEffect(() => {
		setBreadCrumbs([<span><i className="fas fa-users"></i> Clients</span>]);
	}, []);

	return (
        <div className="content_inner">
            <ClientsTable/>
		</div>
	);
};