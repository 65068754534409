import React, {useEffect, useState} from 'react';
import UserForm from "../../forms/User"



export default function User(props) {

    let id = props.match.params.id;
    if(id == 'new') id = null;

    return(
        <div className="content_inner">
            <UserForm id={id}/>
        </div>
	);
}