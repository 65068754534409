import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,Input,
} from 'antd';

export default function Home(props) {

    const [form] = Form.useForm();
    const history = useHistory();


    function getStartedClicked(data){
        history.push({
            pathname: '/signUp',
            state: data
        });
    }


    return (
        <div className="content_inner">
            <div className="container">
                <div style={{display:"flex", flexWrap:"wrap"}}>
                    <div style={{margin:"110px 0"}}>
                        <h1 className="main-title pull-left">Metabolic Health Systems</h1>
                        <div>Giving you a recognised and insured scope of practice for nutrition.</div>
                        <label htmlFor="in-email-home" className="sr-only">Enter your email</label>

                        <Form

                            form={form}
                            name="register"
                            onFinish={getStartedClicked}
                            initialValues={{
                                residence: ['zhejiang', 'hangzhou', 'xihu'],
                                prefix: '86',
                            }}
                            scrollToFirstError
                            style={{ margin:"auto"}}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    }
                                ]}
                            >
                                <Input.Search
                                    placeholder="Enter your email"
                                    enterButton="Get Started"
                                    style={{paddingTop:5}}
                                    onSearch={(v,e)=>{form.submit();}}
                                />
                            </Form.Item>
                        </Form>

                    </div>
                    <div style={{flex:"1",minWidth:"280px"}}>
                        <picture id="testing-img-03" className="testing-img" style={{display:"block"}}>
                            <img src="/images/laptop_phone.png"  alt="Trainerize personal training workout app for fitness professionals and fitness clubs"/>
                        </picture>
                    </div>
                </div>
            </div>

        </div>
    );
}