import React from 'react';
import { Layout, Menu, Dropdown, Button,Avatar, Modal  } from 'antd';
import { useHistory } from "react-router-dom";
import SupportForm from "../forms/Support"
//import './sider.less';
import {NavLink} from "react-router-dom";
import useToken from '../hooks/useToken';
import useWindowDimensions from "../components/useWindowDimensions";
import {Form} from "antd/lib/index";

const { Header } = Layout;
const { SubMenu } = Menu;

export default function HeaderNav(props) {

    const { height, width } = useWindowDimensions();

	let self = this;
    const { user, setUser } = useToken();
	const history = useHistory();

    const [supportModalOpen, setSupportModalOpen] = React.useState(false);
    const [modalForm]     = Form.useForm();


	function logout(){
        props.setUser(false);
		history.push("/login");
	}

	const menu = (
		<Menu>
			<Menu.Item>
				<NavLink to="/profile" activeClassName="current">
					<i className="fas fa-user-edit"></i> Profile
				</NavLink>
			</Menu.Item>
            <Menu.Item>
                <a onClick={()=>setSupportModalOpen(true)}>
                    <i className="fad fa-hands-helping"/> Support
                </a>
            </Menu.Item>
			<Menu.Item>
				<a onClick={logout}>
					<i className="fas fa-sign-out-alt"></i> Logout
				</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<Header style={{ padding: 0, backgroundColor:"white", color: "#fff" }}>
			<div className="" style={{display:"flex"}}>
                {width < 768 &&
				<>
					<div style={{flex: "1", paddingLeft:"53px"}}>
						<img className="logo" src="/images/logo.png" style={{height: "46px"}}/>
						<span style={{
							color: "black",
							paddingLeft: (width >= 768) ? "20px" : "0px",
							fontSize: "20px"
						}}>
							{user.type.charAt(0).toUpperCase() + user.type.slice(1)}
							{user.trial && <span> &nbsp; - Free Trial</span>}
						</span>
					</div>
                    <div style={{paddingRight:"15px"}}>
                        <Dropdown overlay={menu} placement="bottomLeft">
                            <Button size="large" style={{height:"50px", borderRadius:"4px",padding:"0 7px"}}>
                                <Avatar icon={<i className="far fa-user"></i>} size="large"/>
                            </Button>
                        </Dropdown>
                    </div>
				</>
                }
                {width >= 768 &&
				<>
					<div style={{flex: "1"}}>
						<span style={{
							color: "black",
							paddingLeft: "20px",
							fontSize: "20px"
						}}>{user.type.charAt(0).toUpperCase() + user.type.slice(1)}
						
							{!!user.trial && <span> &nbsp; - Free Trial</span>}
						</span>
						
					</div>
                    <div style={{paddingRight:"15px"}}>
                        <Dropdown overlay={menu} placement="bottomLeft">
                            <Button size="large" style={{height:"50px", borderRadius:"15px"}}>
                                <Avatar src={(user.profile_image)?user.profile_image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/>
                                <span style={{paddingLeft:"5px"}}>{user.name}</span>
                            </Button>
                        </Dropdown>
                    </div>
				</>
                }

			</div>

            <Modal
                visible={supportModalOpen}
                onOk={() => {modalForm.submit();}}
                onCancel={()=>setSupportModalOpen(false)}
                title="Support"
                okText="Save"
                cancelText="Close"
				//centered={true}
            >
                <SupportForm form={modalForm} onFinish={()=>setSupportModalOpen(false)}/>
            </Modal>
		</Header>
	);

}

/*
<div>
					<div></div>
					<div>
						<Dropdown overlay={menu} placement="bottomLeft">
							<Button>bottomLeft</Button>
						</Dropdown>
					</div>
				</div>
 */