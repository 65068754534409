
import React, { useState } from 'react';

export default function User() {
	const getToken = () => {
        const tokenString = localStorage.getItem('token');
        return tokenString;
    };
    const getUser = () => {
        return JSON.parse(localStorage.getItem('user'));
    };



	const [token, setToken] = useState(getToken());
    const [user, setUser]   = useState(getUser());

	function storeUser(data,token){
		if(data){
		    if(token) {
                localStorage.setItem('token', token);
                setToken(token);
            }
            localStorage.setItem('user', JSON.stringify(data));
		}else{
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setToken(null);
		}

        setUser(data);
	};

	return {
		token,
        user,
		setUser: storeUser,
	}
}

/*
export const isLoggedIn = () => {
	if (localStorage.getItem('token')) return true;
	return false;
}
*/