import React, {useEffect, useState} from 'react';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { useHistory } from "react-router-dom";
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
	Card,
	Modal
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import useToken from "../hooks/useToken";
import API2 from "../components/api2";
import {notification} from "antd/lib/index";
import TermsAndConditionsText2 from "../pages/TermsAndConditionsText2";

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const { Meta } = Card;

export default function Register(props) {

    const [form] = Form.useForm();
    const { user, setUser } = useToken();
    const AP = API2();
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

	const [modelOpen, setModalOpen] = useState(false);
	
    const [isTrial, setIsTrial] = useState(history.location.pathname == "/signUpTrial");

    useEffect(() => {
        if(history.location.state){
            form.setFieldsValue(history.location.state);
		}
    }, []);

	const handleSubmit = async (data) => {
        setIsSaving(true);
        data.plan = plan;

		if(isTrial){
			data.isTrial = true;
		}

        AP.post('/coach',data)
            .then(function(res){

                notification.success({
                    message: `Saved`,
                    placement: 'bottomRight',
                    duration:1,
                    closeIcon : (<div></div>)
                });

				setUser(res.user, res.access_token);
				window.location.replace("/dashboard");

            })
            .catch(function(res){
                setIsSaving(false);
                if(res.response.data.errors) {
                    let errorList = {};
                    for (let error of res.response.data.errors) {
                        if (!errorList[error.input]) errorList[error.input] = [];
                        errorList[error.input].push(error.message);
                    }
                    for (let [input, errors] of Object.entries(errorList)) {
                        form.setFields([
                            {
                                name: input,
                                errors: errors,
                            },
                        ]);
                    }
                }
            });
	};

	const [cardCvc,    setCardCvc]    = useState('');
	const [cardExpiry, setCardExpiry] = useState('');
	const [cardFocus,  setCardFocus]  = useState('');
	const [cardName,   setCardName]   = useState('');
	const [cardNumber, setCardNumber] = useState('');

    const [plan, setPlan] = useState('yearly');

	const handleInputFocus = (e) => {
		let id = e.target.id.substr(13);

		setCardFocus(id);
	}

	function onFormChange(changedFields, allFields){

		let name  = changedFields[0].name[0];
		let value = changedFields[0].value;

		if(name=='cardcvc')    setCardCvc(value);
		if(name=='cardexpiry') setCardExpiry(value);
		if(name=='cardname')   setCardName(value);
		if(name=='cardnumber') setCardNumber(value);
	}

    return(
    	<>
			<Form
				{...formItemLayout}
				form={form}
				name="register"
				onFinish={handleSubmit}
				initialValues={{
					residence: ['zhejiang', 'hangzhou', 'xihu'],
					prefix: '86',
				}}
				scrollToFirstError
				style={{ margin:"auto"}}
				onFieldsChange={onFormChange}
			>
				<Form.Item
					name="first_name"
					label="First Name"
					rules={[
						{
							required:true,
							message:'Please input your first name!',
							whitespace:true
						}
					]}
				>
					<Input />
				</Form.Item>
                <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                        {
                            required:true,
                            message:'Please input your last name!',
                            whitespace:true
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

				<Form.Item
					name="email"
					label="E-mail"
					rules={[
						{
							type: 'email',
							message: 'The input is not valid E-mail!',
						},
						{
							required: true,
							message: 'Please input your E-mail!',
						}
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="password"
					label="Password"
					rules={[
						{
							required: true,
							message: 'Please input your password!',
						},
					]}
					hasFeedback
				>
					<Input.Password />
				</Form.Item>

				<Form.Item
					name="confirm"
					label="Confirm Password"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: 'Please confirm your password!',
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject('The two passwords that you entered do not match!');
							},
						}),
					]}
				>
					<Input.Password />
				</Form.Item>

					
				{isTrial && <>
					<div class="ant-row ant-form-item" style={{rowGap:0}}>
						<div class="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
							
						</div>
						<div class="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
							<h3 style={{color:"red"}}>* All plans contain a free 3 month trial</h3>
						</div>
					</div>
				</>}

                <Form.Item
                    label="Plan"
                >
					<div style={{display:"flex"}}>
						<Card
							hoverable
							style={{ width: 240, marginRight:"10px" }}
                            className={'green-card '+((plan=='yearly')?'selected':'')}
                            onClick={() => setPlan('yearly')}
						>
							<Meta 
								title={<>$179 <span style={{fontSize:"10px"}}>USD</span> Yearly</>}
							/>
						</Card>
						<Card
							hoverable
							style={{ width: 240, marginLeft:"10px" }}
                            className={'green-card '+((plan=='monthly')?'selected':'')}
							onClick={() => setPlan('monthly')}
						>
							<Meta 
								title={<>$19 <span style={{fontSize:"10px"}}>USD</span> Monthly</>} 
								description={<>
									<div>$228 per year</div>
								</>} 
							/>
						</Card>
						
					</div>
                </Form.Item>




				
				<Form.Item
					label="Credit Card"
				>

					<Row gutter="20">
						<Col>
							<Cards
								cvc={cardCvc}
								expiry={cardExpiry}
								focused={cardFocus}
								name={cardName}
								number={cardNumber}
							/>


						</Col>




						<Col flex="1">
							<div style={{paddingTop:"20px"}}>
								<Form.Item
									name="cardnumber"
									rules={[
										{
											required:true,
											message:'Required!',
											whitespace:true
										}
									]}
								>
									<Input
										type="tel"
										placeholder="Number"
										onFocus={handleInputFocus}
									/>
								</Form.Item>

								<Form.Item
									name="cardname"
									rules={[
										{
											required:true,
											message:'Required!',
											whitespace:true
										}
									]}
								>
									<Input
										placeholder="Name"
										onFocus={handleInputFocus}
									/>
								</Form.Item>

								<Row gutter="20">
									<Col style={{width:"125px"}}>

										<Form.Item
									name="cardexpiry"
									rules={[
										{
											required:true,
											message:'Required!',
											whitespace:true
										}
									]}
								>
									<Input
										placeholder="Expiry"
										onFocus={handleInputFocus}
									/>
								</Form.Item>
									</Col>
									<Col flex={"1"}>

										<Form.Item
											name="cardcvc"
											rules={[
												{
													required:true,
													message:'Required!',
													whitespace:true
												}
											]}
										>
											<Input
												placeholder="CVC"
												onFocus={handleInputFocus}
											/>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>

				</Form.Item>
				

				<Form.Item
                    style={{flex: 1, margin: 0}}
                    name="terms_agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Accepting agreement is required'),
                        },
                    ]}
					{...tailFormItemLayout}
                >
                    <Checkbox style={{zoom:1.5}}>
                        <span>I have read and agree to the </span>
                        <a onClick={() => setModalOpen(true)} style={{borderBottom:"1px solid #50A6F1", color:"black"}}>agreement</a>
                        
                    </Checkbox>
                </Form.Item>


				<div>
					<Button type="primary" htmlType="submit" loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Register'}</Button>
				</div>
			</Form>





			<Modal
                visible={modelOpen}
                title={<h3 style={{fontSize:"23px"}}>TERMS AND CONDITIONS</h3>}
                onCancel={()=>setModalOpen(false)}
				//centered={true}
                width={"80vw"}
                footer={<Button type="primary" size="small" shape="round" onClick={()=>setModalOpen(false)}>Close</Button>}
            >
				<div  style={{maxHeight:"70vh", overflow:"auto"}}>
					<TermsAndConditionsText2/>
				</div>

            </Modal>

		</>

    )
}

