import React, {Component, useContext, useEffect} from "react";

import RegisterAppForm from '../forms/registerApp';

export default function ExpressInterest2() {

    return (
		<div className="login-wrapper" style={{paddingTop:"50px",maxWidth:"800px",margin:"0 auto", padding:"30px"}}>
			<p style={{paddingBottom:"40px"}}>An expressing of interest will send an email to your preferred App\Website letting them know you are interested in integrating Metabolic Health services into them.</p>
            <RegisterAppForm/>
        </div>
    );
}