import React, {useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
	Divider,
	Card,
	Row,
	Col,
    DatePicker
} from 'antd';
import Cards from 'react-credit-cards';
import Upload from "../components/Uploader";
import useToken from "../hooks/useToken";
import {notification} from "antd/lib/index";
import Moment from 'react-moment';
import moment from 'moment'




const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function ProfileForm(props) {
    const [form] = Form.useForm();
	const [formData, setFormData] = useState([]);
    const { user, setUser } = useToken();
	const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
	const [paymentMethod, setPaymentMethod]   = useState(null);

	const [cardCvc,    setCardCvc]    = useState('');
	const [cardExpiry, setCardExpiry] = useState('');
	const [cardFocus,  setCardFocus]  = useState('');
	const [cardName,   setCardName]   = useState('');
	const [cardNumber, setCardNumber] = useState('');

    const AP = API2();
    useEffect(() => {
        AP.get('/profile')
            .then(function(res){
                if(res.dob){
                    res.dob = moment(res.dob * 1000);
                }

                if(res.trial){
                    res.subscription = res.trial==1 ? 'monthly' : 'yearly';
                }
                else if(!res.subscription){
                    res.subscription = "";
                }

				setFormData(res);
            	form.setFieldsValue(res);

				if(res.payment_method) {
					setPaymentMethod(res.payment_method);
				}

                if(res.profile_image) {
                    setFiles([{
                        uid: res.profile_image.id,
                        name: res.profile_image.name,
                        status: 'done',
                        url: res.profile_image.endpoint,
                        thumbUrl: res.profile_image.endpoint
					}]);
                }
				setIsLoading(false);
            });
    }, []);



	function saveForm(data){

	    if(data.dob){
            data.dob = data.dob.unix();
        }

	    //get profile image value
        data['profile_image_id'] = null;
	    for(let f of files){
	        if(f.attachment_id) data['profile_image_id'] = f.attachment_id;
            else data['profile_image_id'] = f.uid;
        }

        if(paymentMethod){
	    	if(paymentMethod=='deleted'){
				data.payment_method = 'deleted';
			}else{
				data.payment_method = true;
			}
		}else{
			data.payment_method = false;
		}

        AP.post('/profile',data).then(function(resp){
          
            const isTrial = user.trial;
            setUser(resp);
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });

            if(isTrial){
                setTimeout(()=>{window.location.reload();},1)
            }
		});




	}

	const handleInputFocus = (e) => {
		let id = e.target.id.substr(13);

		setCardFocus(id);
	}

	function onFormChange(changedFields, allFields){

		let name  = changedFields[0].name[0];
		let value = changedFields[0].value;

		if(name=='cardcvc')    setCardCvc(value);
		if(name=='cardexpiry') setCardExpiry(value);
		if(name=='cardname')   setCardName(value);
		if(name=='cardnumber') setCardNumber(value);
	}

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="profile"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
			onFieldsChange={onFormChange}
        >
			<Form.Item
				name="profile_image_id"
				label="Profile Image"
			>
				<Upload files={files} maxCount={1} setFiles={setFiles}/>
			</Form.Item>

            <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                    {
                        required:true,
                        message:'Please input your first name!',
                        whitespace:true
                    }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                    {
                        required:true,
                        message:'Please input your last name!',
                        whitespace:true
                    }
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    }
                ]}
            >
                <Input type='email'/>
            </Form.Item>



            <Form.Item
                name="mobile"
                label="Mobile"
            >
                <Input/>
            </Form.Item>



            {user.type == "client" &&
            <>
                <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a gender',
                        }
                    ]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                    >
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="dob"
                    label="Date Of Birth"
                    help="DD/MM/YYYY"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <DatePicker format="DD/MM/YYYY"/>
                </Form.Item>

                <Form.Item
                    name="height"
                    label="Height"
                >
                    <Input addonAfter="cm"/>
                </Form.Item>

                <Form.Item
                    name="weight"
                    label="Weight"
                >
                    <Input addonAfter="kg"/>
                </Form.Item>
            </>
            }

            <Form.Item
                name="password"
                label="Password"
                hasFeedback
            >
                <Input.Password 
                    placeholder="Leave empty to not change" 
                    autocomplete="new-password"
                />
            </Form.Item>

			{user.type == 'coach' &&
			<>
				<Divider plain>Payment Details</Divider>

				<Form.Item
					label="Paid Until"
				>
					<Moment format="DD/MM/YYYY" unix style={{top:0,position:"absolute"}}>{formData.paid_until}</Moment>
				</Form.Item>
                
                {!!user.trial && <>
                    <Form.Item
                        label="Trial"
                    >
                        Yes
                    </Form.Item>
                </>}

                

				<Form.Item
					label="Payment Method"
				>
					{paymentMethod && paymentMethod!='deleted' &&
					<Card style={{width: 300}}>
						<div style={{display:"flex"}}><div style={{width:"60px"}}>Type:</div><div>{paymentMethod.brand}</div></div>
						<div style={{display:"flex"}}><div style={{width:"60px"}}>Number:</div><div>**** **** **** {paymentMethod.last4}</div></div>
						<div style={{display:"flex"}}><div style={{width:"60px"}}>Expiry:</div><div>{paymentMethod.exp_month}/{paymentMethod.exp_year}</div></div>
						<div style={{position: "absolute", top: 0, right: 0, padding: "10px"}}>
							<Button onClick={() => setPaymentMethod('deleted')}><i className="fas fa-trash"></i></Button>
						</div>
					</Card>
					}
					{!isLoading && (!paymentMethod || paymentMethod=='deleted') &&
					<Row gutter="20">
						<Col>
							<Cards
								cvc={cardCvc}
								expiry={cardExpiry}
								focused={cardFocus}
								name={cardName}
								number={cardNumber}
							/>


						</Col>




						<Col flex="1">
							<div style={{paddingTop:"20px"}}>
								<Form.Item
									name="cardnumber"
								>
									<Input
										type="tel"
										placeholder="Number"
										onFocus={handleInputFocus}
									/>
								</Form.Item>

								<Form.Item
									name="cardname"
								>
									<Input
										placeholder="Name"
										onFocus={handleInputFocus}
									/>
								</Form.Item>

								<Row gutter="20">
									<Col style={{width:"125px"}}>

										<Form.Item
											name="cardexpiry"
										>
											<Input
												placeholder="Expiry"
												onFocus={handleInputFocus}
											/>
										</Form.Item>
									</Col>
									<Col flex={"1"}>

										<Form.Item
											name="cardcvc"
										>
											<Input
												placeholder="CVC"
												onFocus={handleInputFocus}
											/>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
					}
				</Form.Item>

                <Form.Item
                    name="subscription"
                    label="Subscription"
                >
                    <Select
                        placeholder="Select a option and change input text above"
                        //disabled={!paymentMethod}
                    >
                        <Option value="yearly">Yearly</Option>
                        <Option value="monthly">Monthly</Option>
                        <Option value="">None</Option>
                    </Select>
                </Form.Item>
			</>
			}

			<Form.Item {...tailFormItemLayout}>
				<Button type="primary" htmlType="submit">Save</Button>
			</Form.Item>
        </Form>
    )
}

