import React, {useState} from 'react';
import { Button,Avatar,Switch } from 'antd';
import {NavLink} from "react-router-dom";
import SearchTable from "../components/SearchTable";
import useWindowDimensions from "../components/useWindowDimensions";

export default function ClientsTable(props) {

    const { height, width } = useWindowDimensions();
    const [showInactive, setShowInactive] = useState(0);

    const riskColor = {
        'high':'red',
        'moderate':'orange',
        'low':'green'
    };

	const columns = [
        {
            title: "Details",
            responsive: ["max_lg"],
            render: (record) => (
                <React.Fragment>
                    {record.name}
                    <br />
                    {record.email}
                    <br />
                    {record.mobile}
                </React.Fragment>
            )
        },
		{
			title: 'Name',
			key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => {return (!a.name)?-1:a.name.localeCompare(b.name)},
            responsive: ["lg"],
            align:'left',
            render: (text, record) => (
                <div>
                    <span><Avatar src={(record.image)?record.image.endpoint:null} icon={<i className="far fa-user"></i>} size="large"/></span>
                    <span style={{paddingLeft:"20px"}}>{record.name}</span>
                </div>
            ),
		},
		{
			title: 'Email',
			key: 'email',
            dataIndex: 'email',
            sorter: (a, b) => {return (!a.email)?-1:a.email.localeCompare(b.email)},
            align:'left',
            responsive: ["lg"]
		},
		{
			title: 'Mobile',
			key: 'mobile',
            dataIndex: 'mobile',
            sorter: (a, b) => a.mobile - b.mobile,
            align:'left',
            responsive: ["lg"]
		},
		{
			title: 'Risk Assessment',
			key: 'hasRiskAssessment',
            dataIndex: 'hasRiskAssessment',
            sorter: (a, b) => {return (!a.risk)?-1:a.risk.localeCompare(b.risk)},
            align:'left',
            render: (text, record) => (
                <div>
                    {record.risk && <span style={{color:riskColor[record.risk]}}>{record.risk.toUpperCase()}</span>}
                </div>
            ),
            responsive: ["lg"]
		},
		{
			title: 'Actions',
            key: 'actions',
            align:'center',
            render: (text, record) => (
				<div>
					<span><NavLink to={"/client/"+record.id}><Button type="primary" shape="round" size="small"><i className="far fa-edit"/>{(width>1200)?"\u00a0View Client":""}</Button></NavLink></span>
				</div>
			),
		},
	];

	return (
        <div>
            <div style={{display:"flex",paddingBottom:"10px", justifyContent:"flex-end"}}>
                <div
                    style={{marginTop:5}}>
                    <span>Inactive Clients: </span>
                    <Switch
                        checked={showInactive}
                        checkedChildren="Showing"
                        unCheckedChildren="Hiding"
                        onChange={() => {
                            setShowInactive(!showInactive);
                        }}
                    />
                </div>
                <NavLink to="/client/new" activeClassName="current" style={{marginLeft:10}}>
                    <Button icon={<i className="far fa-envelope-open"/>}> &nbsp;Invite Client </Button>
                </NavLink>
            </div>
            <SearchTable
				columns={columns}
				url="/clients"
				filters={{'active':showInactive}}
				 rowClassName={function(record, index){
				 	if(record.active==0) return "inactive";
				 }}
			/>
        </div>
	);
};