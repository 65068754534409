import '../App.css';
import React, { useState } from 'react';
import {BrowserRouter, NavLink , Route, Switch} from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Clients from "../pages/Client/Clients";
import Client from "../pages/Client/Client";
import CreateClient from "../pages/Client/CreateAccount";
import Coaches from "../pages/Coach/Coaches";
import Coach from "../pages/Coach/Coach";
import Users from "../pages/User/Users";
import User from "../pages/User/User";
import CheckIns from "../pages/CheckIn/CheckIns";
import CheckIn from "../pages/CheckIn/CheckIn";
import BodyCompositionsPage from "../pages/CheckIn/BodyCompositions";
import BodyCompositionPage from "../pages/CheckIn/BodyComposition";
import APIKey from "../pages/API/APIKey";
import APIDocumentation from "../pages/API/Documentation";
import LinkedCompanies from "../pages/Company/Linked";
import EmailTemplates from "../pages/email/Templates";
import EmailTemplate from "../pages/email/Template";
import Profile from "../pages/Profile";
import DataExport from "../pages/DataExport";


import PrivateRoute from '../hooks/PrivateRoute'
import BreadCrumbContext from "../contexts/breadCrump";


import { Layout, Menu, Breadcrumb } from 'antd';


import Header from './header';
import Footer from './footer';
import Sider from './sider';
import AddCoach from "../pages/Client/AddCoach";

import EmailHistory from "../pages/email/History";
import useToken from "../hooks/useToken";
import APIIFrame from "../pages/API/iframe";

const { Content } = Layout;
const { SubMenu } = Menu;




function BackendLayout(props) {


    const { user, setUser } = useToken();
	const [breadcrumbs,setBreadcrumbs] = useState([]);
    let isPaid = false;
    if(user.type != 'coach'){
        isPaid = true;
    }
    else if(user.trial){
        isPaid = (user.paid_until||0) > ((Date.now() / 1000));
    }else{
        isPaid = (user.paid_until||0) > ((Date.now() / 1000) - 60*60*24*60);//1 month
    }
debugger;
	return (
		<Layout style={{ minHeight: '100vh',maxWidth:"100%" }}>


			<BreadCrumbContext.Provider value={setBreadcrumbs}>
				<BrowserRouter>
                    {isPaid &&
                    <Sider/>
                    }
					<Layout className="site-layout">
						<Header  setUser={props.setUser}/>
						<Content style={{ margin: '0 16px' }}>
							<Breadcrumb style={{ margin: '16px 0' }}>
								{breadcrumbs.map((value, index) => {
									return <Breadcrumb.Item key={index}>{value}</Breadcrumb.Item>
								})}
							</Breadcrumb>
                            {isPaid &&
                            <Switch>
                                <PrivateRoute path="/dashboard" component={Dashboard}/>
                                <PrivateRoute path="/clients" component={Clients}/>
                                <PrivateRoute path="/client/:id/checkin/:checkinid" component={CheckIn}/>
                                <PrivateRoute path="/client/:id/body-composition/:bodyCompositionId" component={BodyCompositionPage}/>
                                <PrivateRoute path="/client/new" component={CreateClient}/>
                                <PrivateRoute path="/client/:id/:tab?" component={Client}/>
                                <PrivateRoute path="/coaches" component={Coaches}/>
                                <PrivateRoute path="/coach/:id/:tab" component={Coach}/>
                                <PrivateRoute path="/coach/:id" component={Coach}/>
                                <PrivateRoute path="/api/key" component={APIKey}/>
                                <PrivateRoute path="/api/iframe" component={APIIFrame}/>
								<PrivateRoute path="/api/documentation/:group?/:controller?/:action?" component={APIDocumentation}/>
                                <PrivateRoute path="/companies" component={LinkedCompanies}/>
                                <PrivateRoute path="/dataExport" component={DataExport}/>
                                <PrivateRoute path="/email/templates" component={EmailTemplates}/>
                                <PrivateRoute path="/email/template/:id" component={EmailTemplate}/>
                                <PrivateRoute path="/email/history" component={EmailHistory}/>
                                <PrivateRoute path="/profile" component={Profile}/>
                                <PrivateRoute path="/join/:id" component={AddCoach}/>
                                <PrivateRoute path="/users" component={Users}/>
                                <PrivateRoute path="/user/:id" component={User}/>
                                <PrivateRoute path="/checkins" component={CheckIns}/>
                                <PrivateRoute path="/checkin/:checkinid" component={CheckIn}/>
                                <PrivateRoute path="/body-compositions" component={BodyCompositionsPage}/>
                                <PrivateRoute path="/body-composition/:bodyCompositionId" component={BodyCompositionPage}/>
                                <PrivateRoute path="/checkin/:checkinid" component={BodyCompositionPage}/>



                            </Switch>
                            || <Switch>
                                <PrivateRoute component={Profile}/>
                            </Switch>
                            }

						</Content>
						<Footer/>
					</Layout>
				</BrowserRouter>
			</BreadCrumbContext.Provider>
		</Layout>
	);
}
// setBreadcrumbs={setBreadcrumbs}
export default BackendLayout;



