import React, {useContext, useEffect, useState} from 'react';
import API2 from "../components/api2";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
    Spin
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import Upload from "../components/Uploader";
import { NavLink,useHistory } from "react-router-dom";
import {notification} from "antd/lib/index";
import moment from 'moment'
import BreadCrumbContext from "../contexts/breadCrump";




const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



export default function UserForm(props) {

    const [form] = Form.useForm();
    const [user, setUser] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [userType, setUserType] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const setBreadCrumbs = useContext(BreadCrumbContext);
    useEffect(() => {
        setBreadCrumbs([
            <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
            (props.id)?<Spin size="small"/>:<span><i className="fas fa-user"/> Create User</span>
        ]);
    }, []);

    const AP = API2();
    useEffect(() => {
        if(props.id) {
            AP.get('/user/'+props.id)
                .then(function (res) {
                    if (res.dob) {
                        res.dob = moment(res.dob * 1000);
                    }

                    setBreadCrumbs([
                        <NavLink to="/users"><span><i className="fas fa-users"/> Users</span></NavLink>,
                        <span><UserOutlined/> {res.name}</span>
                    ]);

                    setUser(res);
                    form.setFieldsValue(res);

                    if (res.profile_image) {
                        setFiles([{
                            uid: res.profile_image.id,
                            name: res.profile_image.name,
                            status: 'done',
                            url: res.profile_image.endpoint,
                            thumbUrl: res.profile_image.endpoint
                        }]);
                    }
                    setIsLoading(false);
                });
        }
    }, []);

    function saveForm(data){
        setIsSaving(true);

        if(data.dob){
            data.dob = data.dob.unix();
        }

        //get profile image value
        data['profile_image_id'] = null;
        for(let f of files){
            if(f.attachment_id) data['profile_image_id'] = f.attachment_id;
            else data['profile_image_id'] = f.uid;
        }

        let url = '/user';
        if(props.id) {
            url += '/'+props.id;
        }
        AP.post(url,data).then(function(res){
            notification.success({
                message: `Saved`,
                placement: 'bottomRight',
                duration:1,
                closeIcon : (<div></div>)
            });
            setIsSaving(false);
            history.push("/user/"+res.id);
        }).catch(function(res){
            setIsSaving(false);
            if(res.response.data.errors) {
                let errorList = {};
                for (let error of res.response.data.errors) {
                    if (!errorList[error.input]) errorList[error.input] = [];
                    errorList[error.input].push(error.message);
                }
                for (let [input, errors] of Object.entries(errorList)) {
                    form.setFields([
                        {
                            name: input,
                            errors: errors,
                        },
                    ]);
                }
            }
        });;
    }

    return(
        <Form
            {...formItemLayout}
            form={form}
            name="profile"
            onFinish={saveForm}
            scrollToFirstError
            style={{ margin:"auto"}}
        >
            <Form.Item
                name="type"
                label="User Type"
                rules={[
                    {
                        required: true,
                        message: 'Please select a type',
                    }
                ]}
            >
                <Select
                    placeholder="Select a option and change input text above"
                    onChange={(value)=>{setUserType(value)}}
                >
                    <Option value="admin">Admin</Option>
                    <Option value="api">API</Option>
                    <Option value="client">Client</Option>
                    <Option value="coach">Coach</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="profile_image_id"
                label="Profile Image"
            >
                <Upload files={files} maxCount={1} setFiles={setFiles}/>
            </Form.Item>

            <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                    {
                        required:true,
                        message:'Please input your first name!',
                        whitespace:true
                    }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                    {
                        required:true,
                        message:'Please input your last name!',
                        whitespace:true
                    }
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    }
                ]}
            >
                <Input />
            </Form.Item>



            <Form.Item
                name="mobile"
                label="Mobile"
            >
                <Input/>
            </Form.Item>



            {userType == "client" &&
            <>
                <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a gender',
                        }
                    ]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                    >
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="dob"
                    label="Date Of Birth"
                    help="DD/MM/YYYY"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <DatePicker format="DD/MM/YYYY"/>
                </Form.Item>

                <Form.Item
                    name="height"
                    label="Height"
                >
                    <Input addonAfter="cm"/>
                </Form.Item>

                <Form.Item
                    name="weight"
                    label="Weight"
                >
                    <Input addonAfter="kg"/>
                </Form.Item>
            </>
            }

            <Form.Item
                name="password"
                label="Password"
                rules={(!props.id)?[
                    {
                        required: true,
                        message: 'Please select a gender',
                    }
                ]:[]}
            >
                <Input.Password placeholder={(props.id)?"Leave empty to not change":""}/>
            </Form.Item>

            <div>
                <Button type="primary" htmlType="submit" style={{float:"right"}} loading={isSaving} style={{float:"right"}}>{(isSaving)?'Saving':'Save'}</Button>
            </div>
        </Form>
	);
}