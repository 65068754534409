import React, {useContext, useEffect, useState} from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form, Input, Button } from 'antd';
import { useHistory } from "react-router-dom";

import { QuestionCircleOutlined } from '@ant-design/icons';
import BreadCrumbContext from "../../contexts/breadCrump";
import useToken from "../../hooks/useToken";
import API2 from "../../components/api2";
import {notification} from "antd/lib/index";
import RegisterClient from '../../forms/registerClient';


export default function AddCoach() {

	const setBreadCrumbs = useContext(BreadCrumbContext);
    const { user, setUser } = useToken();
    const [coach, setCoach] = useState({});
    const [linked, setLinked] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [wrongUser, setWrongUser] = useState(false);
    const [isInvite, setIsInvite] = useState(false);
    const history = useHistory();
    const AP = API2();

    useEffect(() => {
        setBreadCrumbs([
            <span><UserOutlined/> Link Coach</span>
        ]);



        try {
            let enc = window.location.pathname.substring(6);
            let dec = atob(enc);
            let data = JSON.parse(dec);

            if(data.iv){
                AP.post('/decrypt',{'value':enc})
                    .then(function(res){
                        setCoach({id:res.coach_id,'name':res.coach_name});
                        if(user.id != res.client_id){
                            setWrongUser(true);
                        }
                        setIsInvite(true);
                        setLoading(false);
                    })
                    .catch(function(res){
                        debugger;
                    });
            }else{
                setCoach({id:data.id,'coach_name':data.name});

                form.setFieldsValue({id:data.id,'coach_name':data.name});

                AP.get('/coach/'+data.id)
                    .then(function(res){
                        setLinked(true);
                        setLoading(false);
                    })
                    .catch(function(res){
                        setLoading(false);
                    });
            }



        }catch(err){
            setLoading(false);
        }

    }, []);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const handleSubmit = async (data) => {
        AP.post('/coach/'+coach.id+'/link')
            .then(function(res){
                notification.success({
                    message: `Saved`,
                    placement: 'bottomRight',
                    duration: 1,
                    closeIcon : (<div></div>)
                });

                history.push("/coaches");
            });


    };
debugger;
	return(
		<div className="content_inner">
			{!loading && (

                (wrongUser && <div>This link is not for the current logged in user</div>)
                || (isInvite && (user.pending && <RegisterClient coach={coach}/> || history.push("/coach/"+coach.id+"/riskAssessment")))
                || (!coach && <div>Invalid Join URL</div>)
                || (user.type=='coach' && <div>A coach having a coach is not currently supported</div>
                || (user.type == 'client' && (
                    (linked && <div>You are already linked to this coach</div>)
                    || (!linked &&
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={handleSubmit}
                        scrollToFirstError
                        style={{margin: "auto"}}
                    >

                        <Form.Item
                            name = 'coach_name'
                            label="Coach Name"
                        >
                            <Input className="readOnly" readOnly/>
                        </Form.Item>

                        <div style={{display:"flex"}}>
                            <Button style={{marginLeft:"auto"}} type="primary" htmlType="submit">Add Coach</Button>
                        </div>



                    </Form>)
                ))
                )
            )}
		</div>
	)
}

